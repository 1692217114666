const sideMenuTexts = {
	virgil: 'Virgil Security, Inc.',
	email: {
		text: 'Email',
		link: 'mailto:support@VirgilSecurity.com',
	},
	twoFactorAuth: {
		title: 'Two-factor authentication',
		description: 'Enter 6-digit code from the 2FA app to verify your identity.',
		label: 'Code',
		recovery: 'Enter recovery code',
	},
	twoFactorRecovery: {
		title: 'Two-factor Reset',
		description: 'Enter one of your recovery codes.',
		label: 'Recovery Code',
	},
	support: 'support',
	edit: 'edit',
	slackSupport: 'Slack Community',
	slackInviteLink: 'https://virgilsecurity.com/join-community',
	supportLink: 'https://dashboard.virgilsecurity.com/support',
	intercom: 'Chat',
	terms: {
		link: 'https://virgilsecurity.com/terms-of-service/',
		text: 'Terms of use',
	},
	gitHubLink: 'https://github.com/VirgilSecurity',
	signOut: 'Sign out',
	changePassword: 'Change Password',

	menu: {
		website: 'Homepage',
		applications: 'Applications',
		documentation: 'Documentation',
		userSettings: 'User Settings',
		support: 'Support',
	},
};

export default sideMenuTexts;
