import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import classnames from 'classnames';

export interface AlertsMdxProviderProps {
	children: React.ReactNode;
	paragraphClass?: string;
	titleClass?: string;
	inlineCode?: string;
}

const AlertsMdxProvider: React.FC<AlertsMdxProviderProps> = ({
	children,
	paragraphClass,
	titleClass,
	inlineCode,
}) => {
	const Paragraph: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = props => {
		return (
			<p className={classnames(paragraphClass, props.className)} {...props}>
				{props.children}
			</p>
		);
	};

	const Strong: React.FC<React.HTMLAttributes<HTMLSpanElement>> = props => {
		return (
			<strong className={classnames(titleClass, props.className)} {...props}>
				{props.children}
			</strong>
		);
	};

	const InlineCode: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
		return (
			<code className={classnames(inlineCode, props.className)} {...props}>
				{props.children}
			</code>
		);
	};

	return (
		<MDXProvider components={{ p: Paragraph, strong: Strong, inlineCode: InlineCode }}>
			<div>{children}</div>
		</MDXProvider>
	);
};

export default AlertsMdxProvider;
