import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// fetch encryptedText`}</span>{`
String decryptedText = channel.decrypt(encryptedText);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// fetch encryptedData`}</span>{`
Data decryptedData = channel.decrypt(encryptedData);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// fetch encryptedText`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedText = channel.decrypt(text = encryptedText)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// fetch encryptedData`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedData = channel.decrypt(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{` = encryptedData)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Fetch encryptedText`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decryptedText = channel.decrypt(text: encryptedText)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Fetch encryptedData`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decryptedData = channel.decrypt(data: encryptedData)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      