import 'normalize.css/normalize.css';
import './src/styles/atom-one-dark.css';
import './src/styles/base.css';
import './src/styles/markdown.css';
import { get } from 'js-cookie';
import { launchIntercom } from './node_utils/intercom';
import React from 'react';
import PageLayout from 'src/layout/PageLayout';

export const wrapPageElement = ({ element, props }) => {
	return <PageLayout {...props}>{element}</PageLayout>;
};

const INTERCOM_ID = process.env.INTERCOM_ID;
const VERSION = process.env.IMAGE_VERSION
	? process.env.IMAGE_VERSION !== 'latest'
		? process.env.IMAGE_VERSION
		: process.env.GIT_BRANCH
	: 'develop';

export const onClientEntry = () => {
	if (INTERCOM_ID) {
		launchIntercom({
			app_id: INTERCOM_ID,
			alignment: 'right',
			horizontal_padding: 20,
			vertical_padding: 20,
			custom_launcher_selector: '#intercom-button',
			hide_default_launcher: false,

			virgilsecurity_referrer: get('virgilsecurity_referrer'),
			utm_source: get('utm_source'),
			utm_medium: get('utm_medium'),
			utm_term: get('utm_term'),
			utm_content: get('utm_content'),
			utm_campaign: get('utm_campaign'),
		});
	}

	console.log('Virgil Dev Docs', VERSION);
};
