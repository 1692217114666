import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If you log out user - you should delete her private key`}</span>{`
eThree.cleanup();`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If you log out user - you should delete her private key`}</span>{`
eThree.cleanup()`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L246",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L246"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.cleanup();
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
eThree.cleanup()
    .then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}>{`() =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.log(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'success'`}</span>{`))
    .catch(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`e`}</span>{` =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.error(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'error: '`}</span>{`, e));`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L126",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L126"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If you log out user - you should delete her private key`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! eThree.cleanUp()`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If you log out user - you should delete her private key`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.cleanup();
} `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`on`}</span>{` PlatformException `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (e) {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
}`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      