import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Virgil JWT SDK is provided as a `}<a parentName="p" {...{
        "href": "https://rubygems.org/"
      }}>{`gem`}</a>{` named `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/virgil-jwt"
      }}><em parentName="a">{`virgil-jwt`}</em></a>{` and available for Ruby 2.1 and newer. The package is distributed via `}<em parentName="p">{`bundler`}</em>{` package manager.`}</p>
    <p>{`To install the package use the command below:`}</p>
    <pre><code parentName="pre" {...{}}>{`gem install virgil-crypto
gem install virgil-jwt
gem install base64url
`}</code></pre>
    <p>{` or add the following line to your Gemfile:`}</p>
    <pre><code parentName="pre" {...{}}>{`gem 'virgil-crypto', '~> 3.6.2'
gem 'virgil-jwt'
`}</code></pre>
    <p>{`and then run`}</p>
    <pre><code parentName="pre" {...{}}>{`bundle
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      