import React, { useContext } from 'react';
import DocsMenu from 'src/components/DocsMenu/DocsMenu';
import classnames from 'classnames';
import styles from './DocsNavigation.module.css';
import Search from 'src/components/Search/Search';
import SearchContext from 'src/components/Search/SearchContext';
import PageLayoutContext from '../../layout/PageLayoutContext';

export interface DocsNavigationProps {
	className?: string;
}

const DocsNavigation: React.FC<DocsNavigationProps> = () => {
	const [isSearchOpen, setIsSearchOpen] = React.useState(false);
	const { isDocsOpen } = useContext(PageLayoutContext);

	const searchClass = classnames(styles.SideMenu, {
		[styles.SideMenuSearch]: isSearchOpen,
		[styles.SideMenuOpen]: isDocsOpen,
	});

	const providerValue = { isSearchOpen: isSearchOpen, setIsSearchOpen: setIsSearchOpen };

	return (
		<SearchContext.Provider value={providerValue}>
			<aside className={searchClass}>
				<div className={styles.ScrollContainer}>
					<div className={styles.ScrollBar}>
						<h2 className={styles.MenuTitle}>Documentation</h2>
						<Search />
						<div className={styles.DocsMenu}>
							<DocsMenu />
						</div>
					</div>
				</div>
			</aside>
		</SearchContext.Provider>
	);
};

export default DocsNavigation;
