import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<strong parentName="p">{`Virgil Core SDK Python`}</strong>{` is provided as a package named `}<strong parentName="p">{`virgil_sdk`}</strong>{`. The package is distributed via pip package manager.`}</p>
    <p>{`The package is available for:`}</p>
    <ul>
      <li parentName="ul">{`Python 2.7.x`}</li>
      <li parentName="ul">{`Python 3.x`}</li>
    </ul>
    <p>{`Installing the package:`}</p>
    <p>{`The Virgil Python SDK is provided as a package named virgil_sdk. The package is distributed via Pypi package management system. To install the pip package use the command below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`pip install virgil_sdk`}</code></pre>
    <p>{`In order to install `}<strong parentName="p">{`Virgil Crypto Library`}</strong>{`, first install latest pip distribution: download `}<a parentName="p" {...{
        "href": "https://bootstrap.pypa.io/get-pip.py"
      }}>{`get-pip.py`}</a>{`
and run it using the python interpreter.`}</p>
    <p>{`Use pip to install the wheel binary packages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`pip install virgil-crypto`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      