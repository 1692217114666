import { Info, Warning } from 'components/Alerts';
import Collapse from 'components/Collapse/Collapse';
import SectionNestedPages from 'components/SectionNestedPages/SectionNestedPages';
import CardLink from 'components/Navigation/CardLink';
import { LanguageTab, LanguageTabs } from 'components/LanguageTabs';
import {
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Paragraph,
	Anchor,
	OrderedList,
	ListItem,
	Img,
	InlineCode,
	Code,
	Hr,
	BlockQuote,
	Bold,
} from './MarkdownComponents';
import { UnorderedList } from '@virgilsecurity/virgil-ui/src/components/Markup';

export default {
	h1: Heading1,
	h2: Heading2,
	h3: Heading3,
	h4: Heading4,
	p: Paragraph,
	strong: Bold,
	a: Anchor,
	ol: OrderedList,
	ul: UnorderedList,
	li: ListItem,
	img: Img,
	inlineCode: InlineCode,
	code: Code,
	hr: Hr,
	SectionNestedPages,
	Info,
	blockquote: BlockQuote,
	Warning,
	Collapse,
	CardLink,
	LanguageTabs,
	LanguageTab,
};
