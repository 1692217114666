import { useState, useEffect } from 'react';
import { canUseDOM } from 'src/utils/domHelpers';

export function useMedia(query: string) {
	const [value, setValue] = useState(canUseDOM() ? false : true);

	useEffect(() => {
		const mql = window.matchMedia(query);
		const handler = () => setValue(mql.matches);
		mql.addListener(handler);
		setValue(mql.matches);
		return () => mql.removeListener(handler);
	}, []);

	return value;
}
