import React, { ReactNode } from 'react';
import styles from './Search.module.css';

export interface SearchParagraphProps {
	children: string;
	searchKey: string;
}

const SearchParagraph: React.FC<SearchParagraphProps> = ({ children, searchKey }) => {
	const re = new RegExp(searchKey, 'gi');
	children = children.replace(re, '^^$&^^');
	const result: ReactNode[] = children.split('^^').map((el, i) => {
		if (i % 2) {
			return <b key={i}>{el}</b>;
		} else {
			return <React.Fragment key={i}>{el}</React.Fragment>;
		}
	}, []);
	return <p className={styles.Paragraph}>{result}</p>;
};

export default SearchParagraph;
