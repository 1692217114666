import React from 'react';

export interface PageLayoutContextProps {
	path: string;
	isDocsOpen: boolean;
	setIsDocsOpen: (boolean) => void;
}

const PageLayoutContext = React.createContext<PageLayoutContextProps>({
	path: '/',
	isDocsOpen: false,
	setIsDocsOpen: () => {},
});

export default PageLayoutContext;
