import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`String decrypted = group.decrypt(encrypted, users.get(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`));`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` messageSender = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.findUsers(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alice@myapp.com'`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decrypted = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` group.decrypt(encrypted, messageSender);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decrypted = group.decrypt(encrypted, users[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`]!!)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decrypted = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! group.decrypt(text: encrypted, from: users[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`]!)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      