import React from 'react';

export interface SearchContextProps {
	isSearchOpen: boolean;
	setIsSearchOpen: (boolean) => void;
}

const SearchContext = React.createContext<SearchContextProps>({
	isSearchOpen: false,
	setIsSearchOpen: () => {},
});

export default SearchContext;
