import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><inlineCode parentName="p">{`EThree`}</inlineCode>{` initializer has plenty of optional parameters to customize it's behaviour. You can easily set them using `}<inlineCode parentName="p">{`EThreeParams`}</inlineCode>{` class:`}</p>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="Java" mdxType="LanguageTab">
        <pre><code parentName="pre" {...{
            "className": "hljs language-java"
          }}><span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// create EThreeParams with mandatory parameters`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// such as identity, tokenCallback and context`}</span>{`
EThreeParams params = `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`new`}</span>{` EThreeParams(`}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`"Alice"`}</span>{`,
                                       tokenCallback,
                                       context);

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// initialize E3Kit with the EThreeParams`}</span>{`
EThree ethree = `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`new`}</span>{` EThree(params);`}</code></pre>
  </LanguageTab>
  <LanguageTab language="Kotlin" mdxType="LanguageTab">
        <pre><code parentName="pre" {...{
            "className": "hljs language-kotlin"
          }}><span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// create EThreeParams with mandatory parameters`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// such as identity, tokenCallback and context`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`val`}</span>{` params = EThreeParams(identity = `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`"Alice"`}</span>{`,
                          tokenCallback = tokenCallback,
                          context = context)

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// initialize E3Kit with the EThreeParams`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`val`}</span>{` ethree = EThree(params = params)`}</code></pre>
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      