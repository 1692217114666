import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`ethree.loadGroup(groupId, users.get(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`)).addCallback(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnResultListener<Group>() {
    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(Group group)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Group loaded and saved locally!`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }
});`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` groupId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'unique_group_id'`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` aliceCard = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.findUsers(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alice@myapp.com'`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` group = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.loadGroup(groupId, aliceCard);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Group loaded and saved locally`}</span></code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}>{`ethree.loadGroup(groupId, users[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`]!!).addCallback(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnResultListener<Group> {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(group: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Group`}</span>{`)`}</span></span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Group loaded and saved locally!`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }
})`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}>{`eThree.loadGroup(id: groupId, initiator: findUsersResult[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`]!) { group, error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` group = group, error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{`
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Group loaded and saved locally!`}</span>{`
}`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      