import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`OnGetTokenCallback tokenCallback = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnGetTokenCallback() {

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@NotNull`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` String `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onGetToken`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` virgilToken;
    }
};

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// EThree initializer has plenty of optional parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to customize it's behaviour. You can easily set them`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// using EThreeParams class`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// create EThreeParams with mandatory parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// such as identity, tokenCallback (a callback that`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// returns tokens obtained from backend) and context`}</span>{`
EThreeParams ethreeParams = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` EThreeParams(identity, tokenCallback, context);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize E3Kit with the EThreeParams`}</span>{`
EThree ethree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` EThree(ethreeParams);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// EThree initializer has plenty of optional parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to customize it's behaviour. You can easily set them`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// using EThreeParams class`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// create EThreeParams with mandatory parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// such as identity, tokenCallback (a callback that`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// returns tokens obtained from backend) and context`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` ethreeParams = EThreeParams(identity, { virgilToken }, context)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize E3Kit with the EThreeParams`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` ethree = EThree(ethreeParams)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-web/blob/master/device.js#L55",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-web/blob/master/device.js#L55"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// The EThree.initialize() function gets the user's JWT`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// and initializes the Virgil E3Kit for further use.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// The EThree.initialize() function must be used on SignUp and SignIn flows.`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'@virgilsecurity/e3kit-browser'`}</span>{`;

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This function returns a token that will be used to`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// authenticate requests to your backend.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This is a simplified solution without any real protection, so`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// here you need to use your application authentication mechanism.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`function`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`authenticate`}</span>{`(`}<span parentName="span" {...{
            "className": "hljs-params"
          }}>{`identity`}</span>{`) `}</span>{`{
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` response = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` fetch(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'http://localhost:3000/authenticate'`}</span>{`, {
        `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`method`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'POST'`}</span>{`,
        `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`headers`}</span>{`: {
            `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'Content-Type'`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'application/json'`}</span>{`,
        },
        `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`body`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`JSON`}</span>{`.stringify({
            `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`identity`}</span>{`: identity
        })
    });
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!response.ok) {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`throw`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`Error`}</span>{`(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`\`Error: `}<span parentName="span" {...{
            "className": "hljs-subst"
          }}>{`\${response.statusText}`}</span>{`\``}</span>{`);
    }

    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` response.json().then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`data`}</span>{` =>`}</span>{` data.authToken);
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Log in as \`alice\``}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` eThreePromise = authenticate(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alice'`}</span>{`).then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`authToken`}</span>{` =>`}</span>{` {

    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// E3kit will call this callback and wait for the Promise resolve.`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` EThree.initialize(tokenCallback);

    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This function makes authenticated request to GET /virgil-jwt endpoint`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`function`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`tokenCallback`}</span>{`(`}<span parentName="span" {...{
            "className": "hljs-params"
          }}></span>{`) `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` response = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` fetch(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'http://localhost:3000/virgil-jwt'`}</span>{`, {
            `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`headers`}</span>{`: {
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// We use bearer authorization, but you can use any other`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// mechanism.`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`Authorization`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`\`Bearer `}<span parentName="span" {...{
            "className": "hljs-subst"
          }}>{`\${authToken}`}</span>{`\``}</span>{`,
            }
        })
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!response.ok) {
            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`throw`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`Error`}</span>{`(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`\`Error: `}<span parentName="span" {...{
            "className": "hljs-subst"
          }}>{`\${response.statusText}`}</span>{`\``}</span>{`);
        }

        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If request was successful we return Promise which will resolve`}</span>{`
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// with token string.`}</span>{`
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` response.json().then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`data`}</span>{` =>`}</span>{` data.virgilToken);
    }
});

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// then you can get instance of EThree in that way:`}</span>{`
eThreePromise.then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`eThree`}</span>{` =>`}</span>{` { `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/* eThree.encrypt/decrypt/lookupPublicKeys */`}</span>{`})

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` eThree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThreePromise;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// EThree initializer has plenty of optional parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to customize it's behaviour. You can easily set them`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// using EThreeParams class`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// create EThreeParams with mandatory parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// such as identity, tokenCallback (a callback that`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// returns tokens obtained from backend) and context`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` params = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThreeParams`}</span>{`(identity: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`,
                                tokenCallback: tokenCallback)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// OR`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// EThreeParams can also be initialized from config plist file`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// see the example of config file here: https://github.com/VirgilSecurity/virgil-e3kit-x/tree/0.8.0/Tests/Data/ExampleConfig`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` configUrl = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Bundle`}</span>{`.main.url(forResource: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"EThreeConfig"`}</span>{`, withExtension: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"plist"`}</span>{`)!

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` params = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThreeParams`}</span>{`(identity: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`,
                                tokenCallback: tokenCallback,
                                configUrl: configUrl)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize E3Kit with the EThreeParams`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` ethree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThree`}</span>{`(params: params)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// not supported yet`}</span>{`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      