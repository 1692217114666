import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><inlineCode parentName="p">{`EThree`}</inlineCode>{` initializer has plenty of optional parameters to customize it's behaviour. You can easily set them using `}<inlineCode parentName="p">{`EThreeParams`}</inlineCode>{` class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` VirgilE3Kit

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// create EThreeParams with mandatory parameters`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// such as identity and tokenCallback`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` params = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThreeParams`}</span>{`(identity: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`,
                               tokenCallback: tokenCallback)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// OR`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// EThreeParams can also be initialized from config plist file`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// see the example of config file here: https://github.com/VirgilSecurity/virgil-e3kit-x/tree/0.8.0/Tests/Data/ExampleConfig`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` configUrl = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Bundle`}</span>{`.main.url(forResource: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"EThreeConfig"`}</span>{`, withExtension: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"plist"`}</span>{`)!

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` params = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThreeParams`}</span>{`(identity: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`,
                               tokenCallback: tokenCallback,
                               configUrl: configUrl)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize E3Kit with the EThreeParams`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` ethree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThree`}</span>{`(params: params)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      