import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Install PureKit Golang with the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`go get -u github.com/VirgilSecurity/virgil-purekit-go/v3`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      