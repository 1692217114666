import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';
import GuideMdxProvider from 'components/Markdown/GuideMdxProvider';
import { GuideProps, TableOfContents } from 'src/types/GuideProps';
import DocsLayout from 'src/layout/DocsLayout';

export const SnippetContext = React.createContext<
	[string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]
>([undefined, () => undefined]);

const SnippetProvider: React.FC = ({ children }) => {
	const state = React.useState<string | undefined>(undefined);
	return <SnippetContext.Provider value={state}>{children}</SnippetContext.Provider>;
};

export interface TableOfContentContextProps {
	tableOfContents: TableOfContents;
}

export const TableOfContentContext = React.createContext<TableOfContentContextProps>({
	tableOfContents: {},
});

interface GuideTemplateProps {
	data: {
		mdx: GuideProps;
	};
}

const GuideTemplate: React.FC<GuideTemplateProps> = ({ data }) => {
	useEffect(() => {
		if (window.location.hash) {
			const elementToScroll = document.getElementById(window.location.hash.replace('#', ''));
			if (elementToScroll) {
				elementToScroll.scrollIntoView();
			}
		}
	}, []);

	return (
		<React.Fragment>
			<TableOfContentContext.Provider value={{ tableOfContents: data.mdx.tableOfContents }}>
				<Helmet>
					<title>{data.mdx.frontmatter.tab_title}</title>
					<meta name="description" content={data.mdx.frontmatter.meta_description} />
					<meta name="keywords" content={data.mdx.frontmatter.meta_keywords.join(', ')} />
				</Helmet>
				<DocsLayout>
					<SnippetProvider>
						<GuideMdxProvider>
							<MDXRenderer>{data.mdx.body}</MDXRenderer>
						</GuideMdxProvider>
					</SnippetProvider>
				</DocsLayout>
			</TableOfContentContext.Provider>
		</React.Fragment>
	);
};

export const pageQuery = graphql`
	query GuideQuery($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			tableOfContents
			frontmatter {
				tab_title
				meta_description
				meta_keywords
			}
		}
	}
`;

export default GuideTemplate;
