import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Receive \`encryptedStreamKeyData\` and download data from the remote storage.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
* Application specific code.
*/`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Prepare streams.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedInputStream = ByteArrayInputStream(encryptedData)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedOutputStream = ByteArrayOutputStream()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Find initiator's Card.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` aliceCard = bobEthree.findUser(aliceIdentity).`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4. Decrypt \`encryptedStreamKeyData\` received peer-to-peer.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` p2pDecryptedStreamKeyData = bobEthree.authDecrypt(Data(p2pEncryptedStreamKeyData), aliceCard).value

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Decrypt stream.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedStreamKeyData = p2pDecryptedStreamKeyData ?: groupDecryptedStreamKeyData

bobEthree.decryptShared(encryptedInputStream, decryptedOutputStream, decryptedStreamKeyData, aliceCard)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Receive \`encryptedStreamKeyData\` and download data from the remote storage.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedData = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Data`}</span>{`()
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedStreamKeyData = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Data`}</span>{`()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Prepare streams.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedInputStream = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`InputStream`}</span>{`(data: encryptedData)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decryptedOutputStream = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`OutputStream`}</span>{`.toMemory()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Find initiator's Card.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` aliceIdentity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` aliceCard = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.findUsers(with: [aliceIdentity]).startSync().`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`().first!.value

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4 Decrypt \`encryptedStreamKeyData\` received peer-to-peer.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` p2pDecryptedStreamKeyData = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.authDecrypt(data: encryptedStreamKeyData)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Decrypt stream.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decryptedStreamKeyData = p2pDecryptedStreamKeyData ?? groupDecryptedStreamKeyData
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.decryptShared(encryptedInputStream, to: decryptedOutputStream, with: decryptedStreamKeyData, verifyWith: aliceCard)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      