import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`OnCompleteListener unregisterListener = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnCompleteListener() {
    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Public key (Card) revoked successfully, private key removed successfully.`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Handle error`}</span>{`
    }
};

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Revokes Card from Virgil Cards Service, deletes Private Key from local storage`}</span>{`
eThree.unregister().addCallback(unregisterListener);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` unregisterListener = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnCompleteListener {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span></span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Public key (Card) revoked successfully, private key removed successfully.`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Handle error`}</span>{`
    }
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Revokes Card from Virgil Cards Service, deletes Private Key from local storage`}</span>{`
eThree.unregister().addCallback(unregisterListener)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L259",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L259"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Revokes Card from Virgil Cards Service, deletes Private Key from local storage`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.unregister();
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
eThree.unregister()
    .then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}>{`() =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.log(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'success'`}</span>{`))
    .catch(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`e`}</span>{` =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.error(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'error: '`}</span>{`, e));`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L277-L279",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L277-L279"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` VirgilE3Kit

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Revokes Card from Virgil Cards Service, deletes Private Key from local storage`}</span>{`
eThree.unregister { error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling here`}</span>{`
    }
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Revokes Card from Virgil Cards Service, deletes Private Key from local storage`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.unregister();
} `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`on`}</span>{` PlatformException `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (e) {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      