import React from 'react';

export interface ReactCommentProps {
	comment: string;
}

const ReactComment = ({ comment }) => {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: `<!-- ${comment} -->`,
			}}
		/>
	);
};

export default ReactComment;
