import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<strong parentName="p">{`Virgil Core SDK .NET`}</strong>{` is provided as a package named `}<strong parentName="p">{`Virgil.SDK`}</strong>{` The package is distributed via `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/nuget/quickstart/use-a-package"
      }}>{`NuGet package`}</a>{` management system.`}</p>
    <p>{`Supported Platforms:`}</p>
    <ul>
      <li parentName="ul">{`.NET Standard 1.1+`}</li>
      <li parentName="ul">{`.NET Framework 4.5+`}</li>
      <li parentName="ul">{`.NET Core 1.0+`}</li>
      <li parentName="ul">{`Universal Windows Platform 10`}</li>
      <li parentName="ul">{`Windows 8.0+`}</li>
      <li parentName="ul">{`Windows Phone 8.1+`}</li>
      <li parentName="ul">{`Xamarin.Android 7.0+`}</li>
      <li parentName="ul">{`Xamarin.iOS 10.0+`}</li>
      <li parentName="ul">{`Xamarin.Mac 3.0+`}</li>
      <li parentName="ul">{`Mono 4.6+ (OSX)`}</li>
    </ul>
    <p>{`Install the package using Package Manager Console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`Run PM> Install-Package Virgil.Crypto
Run PM> Install-Package Virgil.SDK`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      