import React, { useContext } from 'react';
import styles from './Search.module.css';
import SearchParagraph from './SearchParagraph';
import { Link } from 'gatsby';
import { MatchesProps } from './Search';
import PageLayoutContext from 'src/layout/PageLayoutContext';

export interface ResultsListProps {
	searchResults: MatchesProps[];
}

const ResultsList: React.FC<ResultsListProps> = ({ searchResults }) => {
	const { setIsDocsOpen } = useContext(PageLayoutContext);

	const closeMobileMenu = () => {
		setIsDocsOpen(false);
	};

	return (
		<ul className={styles.List}>
			{searchResults.map(res => {
				return (
					<li className={styles.Item} key={res.id}>
						<Link className={styles.Link} to={res.fields.slug} onClick={closeMobileMenu}>
							{res.frontmatter.tab_title}
							<SearchParagraph searchKey={res.searchKey}>{res.matches}</SearchParagraph>
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default ResultsList;
