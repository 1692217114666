import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`E3Kit's underlying crypto library is compiled to WebAssembly. You need to make sure your target browsers `}<a parentName="p" {...{
          "href": "https://caniuse.com/#search=WebAssembly"
        }}>{`support WebAssembly`}</a>{`. We also have asm.js fallback for environments that don't support WebAssembly. The downside of it is much slower download and execution time.`}</p>
    </blockquote>
    <h4 {...{
      "id": "install-e3kit"
    }}>{`Install E3Kit`}</h4>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="NPM" mdxType="LanguageTab">
        <pre><code parentName="pre" {...{
            "className": "hljs language-sh"
          }}>{`npm install @virgilsecurity/e3kit-browser`}</code></pre>
  </LanguageTab>
  <LanguageTab language="Yarn" mdxType="LanguageTab">
        <pre><code parentName="pre" {...{
            "className": "hljs language-sh"
          }}>{`yarn add @virgilsecurity/e3kit-browser`}</code></pre>
  </LanguageTab>
  <LanguageTab language="UMD" mdxType="LanguageTab">
        <p><strong parentName="p">{`WebAssembly`}</strong></p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-html"
          }}><span parentName="code" {...{
              "className": "hljs-tag"
            }}>{`<`}<span parentName="span" {...{
                "className": "hljs-name"
              }}>{`script`}</span>{` `}<span parentName="span" {...{
                "className": "hljs-attr"
              }}>{`type`}</span>{`=`}<span parentName="span" {...{
                "className": "hljs-string"
              }}>{`"text/javascript"`}</span>{` `}<span parentName="span" {...{
                "className": "hljs-attr"
              }}>{`src`}</span>{`=`}<span parentName="span" {...{
                "className": "hljs-string"
              }}>{`"https://unpkg.com/@virgilsecurity/e3kit-browser/dist/browser.umd.js"`}</span>{`>`}</span><span parentName="code" {...{
              "className": "hljs-tag"
            }}>{`</`}<span parentName="span" {...{
                "className": "hljs-name"
              }}>{`script`}</span>{`>`}</span></code></pre>
        <p><strong parentName="p">{`asm.js`}</strong>{` (Use this only if your target environments don't support WebAssembly)`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-html"
          }}><span parentName="code" {...{
              "className": "hljs-tag"
            }}>{`<`}<span parentName="span" {...{
                "className": "hljs-name"
              }}>{`script`}</span>{` `}<span parentName="span" {...{
                "className": "hljs-attr"
              }}>{`type`}</span>{`=`}<span parentName="span" {...{
                "className": "hljs-string"
              }}>{`"text/javascript"`}</span>{` `}<span parentName="span" {...{
                "className": "hljs-attr"
              }}>{`src`}</span>{`=`}<span parentName="span" {...{
                "className": "hljs-string"
              }}>{`"https://unpkg.com/@virgilsecurity/e3kit-browser/dist/browser.asmjs.umd.js"`}</span>{`>`}</span><span parentName="code" {...{
              "className": "hljs-tag"
            }}>{`</`}<span parentName="span" {...{
                "className": "hljs-name"
              }}>{`script`}</span>{`>`}</span></code></pre>
  </LanguageTab>
    </LanguageTabs>
    <h4 {...{
      "id": "initialize-virgil-crypto"
    }}>{`Initialize Virgil Crypto`}</h4>
    <h5 {...{
      "id": "create-react-app-optional"
    }}>{`Create React App (optional)`}</h5>
    <p>{`If you're working with Create React App, first, you need an ability to tweak create-react-app configs. You have 2 options here:`}</p>
    <ul>
      <li parentName="ul">{`Override options using `}<a parentName="li" {...{
          "href": "https://github.com/timarney/react-app-rewired"
        }}>{`react-app-rewired`}</a>{` or its alternatives.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://facebook.github.io/create-react-app/docs/available-scripts#npm-run-eject"
        }}>{`Eject`}</a>{`. Please note that this is a one-way operation.`}</li>
    </ul>
    <p>{`Now you need to go through the next (Webpack) step.`}</p>
    <h5 {...{
      "id": "webpack"
    }}>{`Webpack`}</h5>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="WebAssembly" mdxType="LanguageTab">
        <p>{`First, you need to install `}<a parentName="p" {...{
            "href": "https://github.com/webpack-contrib/file-loader"
          }}>{`file-loader`}</a>{` if you haven't used it yet:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-sh"
          }}>{`npm install file-loader --save-dev`}</code></pre>
        <p>{`Second, you need to add a `}<a parentName="p" {...{
            "href": "https://webpack.js.org/configuration/module/#rule"
          }}>{`rule`}</a>{` to copy WebAssembly file:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-js"
          }}>{`{
  `}<span parentName="code" {...{
              "className": "hljs-attr"
            }}>{`test`}</span>{`: `}<span parentName="code" {...{
              "className": "hljs-regexp"
            }}>{`/\\.wasm$/`}</span>{`,
  type: `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'javascript/auto'`}</span>{`,
  `}<span parentName="code" {...{
              "className": "hljs-attr"
            }}>{`loader`}</span>{`: `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'file-loader'`}</span>{`,
}`}</code></pre>
  </LanguageTab>
  <LanguageTab language="asm.js" mdxType="LanguageTab">
        <p>{`First, you need to `}<a parentName="p" {...{
            "href": "https://webpack.js.org/configuration/node"
          }}>{`disable mocking of Node.js modules and globals`}</a>{` in your Webpack config:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-js"
          }}>{`node: `}<span parentName="code" {...{
              "className": "hljs-literal"
            }}>{`false`}</span></code></pre>
        <p>{`And then simply import the library:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-js"
          }}><span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` { initCrypto, VirgilCrypto } `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'virgil-crypto/dist/browser.asmjs.es'`}</span>{`;`}</code></pre>
  </LanguageTab>
    </LanguageTabs>
    <h4 {...{
      "id": "usage"
    }}>{`Usage`}</h4>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="NPM" mdxType="LanguageTab">
        <p><strong parentName="p">{`WebAssembly`}</strong></p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-javascript"
          }}><span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'@virgilsecurity/e3kit-browser'`}</span>{`;`}</code></pre>
        <p><strong parentName="p">{`asm.js`}</strong>{` (Use this only if your target environments don't support WebAssembly)`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-javascript"
          }}><span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'@virgilsecurity/e3kit-browser/dist/browser.asmjs.es'`}</span>{`;`}</code></pre>
  </LanguageTab>
  <LanguageTab language="UMD" mdxType="LanguageTab">
        <pre><code parentName="pre" {...{
            "className": "hljs language-html"
          }}><span parentName="code" {...{
              "className": "hljs-tag"
            }}>{`<`}<span parentName="span" {...{
                "className": "hljs-name"
              }}>{`script`}</span>{`>`}</span><span parentName="code" {...{
              "className": "javascript"
            }}>{`
    `}<span parentName="span" {...{
                "className": "hljs-keyword"
              }}>{`const`}</span>{` EThree = `}<span parentName="span" {...{
                "className": "hljs-built_in"
              }}>{`window`}</span>{`.E3kit.EThree;
`}</span><span parentName="code" {...{
              "className": "hljs-tag"
            }}>{`</`}<span parentName="span" {...{
                "className": "hljs-name"
              }}>{`script`}</span>{`>`}</span></code></pre>
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      