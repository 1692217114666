import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<strong parentName="p">{`Virgil Core SDK Java/Android`}</strong>{` is provided as a package named `}<strong parentName="p">{`com.virgilsecurity.sdk`}</strong>{`. The package is distributed via Maven repository.`}</p>
    <p>{`The package is available for:`}</p>
    <ul>
      <li parentName="ul">{`Java 7+`}</li>
      <li parentName="ul">{`Android API 21+`}</li>
    </ul>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="Maven" mdxType="LanguageTab">
        <p>{`To integrate Virgil Core SDK into your Java project using Maven, set up dependencies in your `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{}}>{`<dependencies>
    <dependency>
        <groupId>com.virgilsecurity.sdk</groupId>
        <artifactId>sdk</artifactId>
        <version>%latest-version%</version>
    </dependency>
</dependencies>
`}</code></pre>
  </LanguageTab>
  <LanguageTab language="Gradle" mdxType="LanguageTab">
        <p>{`To integrate Virgil Core SDK into your Java project using Gradle, set up dependencies in your `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{`.`}</p>
        <p>{`Use this packages for Android projects:`}</p>
        <pre><code parentName="pre" {...{}}>{`    implementation 'com.virgilsecurity.sdk:sdk-android:<latest-version>'
`}</code></pre>
        <p>{`The `}<strong parentName="p">{`<latest-version`}{`>`}</strong>{` of the SDK can be found in the `}<a parentName="p" {...{
            "href": "https://mvnrepository.com/artifact/com.virgilsecurity.sdk/sdk"
          }}>{`Maven Central Repository`}</a>{` or in the header of current readme.`}</p>
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      