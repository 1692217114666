import React, { useState } from 'react';
import { MenuItem } from 'content/SideMenu.yml';
import DocsMenuLink from './DocsMenuLink';
import DocsMenuExpandingItem from './DocsMenuExpandingItem';
import classNames from 'classnames';
import { MapWithDefault, useCurrentLink } from './utils';
import styles from './DocsMenu.module.css';
import PageLayoutContext from 'src/layout/PageLayoutContext';
import { useContext } from 'react';

const detailsClassMap = new MapWithDefault(styles.Item, [[0, styles.TopLevelItemContainer]]);

const detailsOpenClassMap = new MapWithDefault(styles.Item, [
	[0, styles.TopLevelItemContainerOpen],
]);

export interface DocsMenuItemProps {
	item: MenuItem;
	depth: number;
}

const DocsMenuItem: React.FC<DocsMenuItemProps> = props => {
	const { item, depth } = props;
	const { path } = useContext(PageLayoutContext);

	const currentLink = useCurrentLink(path);
	const isMenuItemOpen = (item, path) => {
		return (
			item.link === currentLink ||
			(item.children && item.children.some(i => isMenuItemOpen(i, path)))
		);
	};

	const isActive = currentLink === item.link;
	const isOpen = isMenuItemOpen(item, path);
	const [isOpenState, setOpenState] = useState(isOpen);
	const hasActive = isOpen && !isOpenState && !isActive;

	let containerClass =
		isOpenState && item.children ? detailsOpenClassMap.get(depth) : detailsClassMap.get(depth);

	if (hasActive) containerClass = classNames(containerClass, styles.HasActive);

	if (item.children) {
		return (
			<li className={containerClass}>
				<DocsMenuExpandingItem
					item={item}
					isOpen={isOpenState}
					setOpen={setOpenState}
					depth={depth}
					isActive={isActive}
				/>
			</li>
		);
	}

	return (
		<li className={containerClass}>
			<DocsMenuLink
				item={item}
				isOpen={isOpenState}
				setOpen={setOpenState}
				depth={depth}
				isActive={isActive}
			/>
		</li>
	);
};

export default DocsMenuItem;
