import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><inlineCode parentName="p">{`EThree`}</inlineCode>{` initializer has two parameters: `}<inlineCode parentName="p">{`identity`}</inlineCode>{` and `}<inlineCode parentName="p">{`token callback`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'package:virgil_e3kit/virgil_e3kit.dart'`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'package:http/http.dart'`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`as`}</span>{` http;

...

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`String`}</span>{` identity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"identity"`}</span>{`;

Future<`}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`String`}</span>{`> tokenCallback() `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`async`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` host = `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`Uri`}</span>{`.parse(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'http://localhost:8080/jwt'`}</span>{`);

    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//android emulator has different routing`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (Platform.isAndroid) {
        host = `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`Uri`}</span>{`.parse(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'http://10.0.2.2:8080/jwt'`}</span>{`);
    }

    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` response = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` http.post(host, body: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'{"identity": identity}'`}</span>{`);
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` resp = jsonDecode(response.body);

    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` resp[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"jwt"`}</span>{`];
};

func initEthree() `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`async`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` ethree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` Ethree.init(identity, tokenCallback);
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.register();

        ...

    } `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`on`}</span>{` PlatformException `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (e) {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//process error`}</span>{`
    }
}
...`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      