import TemporaryCreateSnippet from "snippets/e3kit/common/temporary/create";
import TemporaryLoadSnippet from "snippets/e3kit/common/temporary/load";
import TemporaryLoadCreatorSnippet from "snippets/e3kit/common/temporary/load-creator";
import TemporaryGetSnippet from "snippets/e3kit/common/temporary/get";
import TemporaryEncryptSnippet from "snippets/e3kit/common/temporary/encrypt";
import TemporaryDecryptSnippet from "snippets/e3kit/common/temporary/decrypt";
import TemporaryDeleteSnippet from "snippets/e3kit/common/temporary/delete";
import * as React from 'react';
export default {
  TemporaryCreateSnippet,
  TemporaryLoadSnippet,
  TemporaryLoadCreatorSnippet,
  TemporaryGetSnippet,
  TemporaryEncryptSnippet,
  TemporaryDecryptSnippet,
  TemporaryDeleteSnippet,
  React
};