import React from 'react';

type LanguageTabProps = {
	language: string;
};

const LanguageTab: React.FC<LanguageTabProps> = ({ children }) => {
	return <React.Fragment>{children}</React.Fragment>;
};

export default LanguageTab;
