import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Listener for findUsers`}</span>{`
OnResultListener<Card> findUsersListener =
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnResultListener<Card>() {
            `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(Card senderCard)`}</span>{` `}</span>{`{
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt data and verify if it was really written by Bob`}</span>{`
                Data decryptedData = eThree.authDecrypt(encryptedData, senderCard);

                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt text and verify if it was really written by Bob`}</span>{`
                String decryptedText = eThree.authDecrypt(encryptedText, senderCard);
            }

            `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
            }
        };

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Lookup destination user public keys`}</span>{`
ethree.findUser(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"bobUID"`}</span>{`).addCallback(findUsersListener);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Listener for findUsers`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` findUsersListener =
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnResultListener<Card> {
            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(senderCard: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Card`}</span>{`)`}</span></span>{` {
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt data and verify if it was really written by Bob`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedData = ethree.authDecrypt(encryptedData, senderCard)

                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt text and verify if it was really written by Bob`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedText = ethree.authDecrypt(encryptedText, senderCard)
            }

            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
            }
        }

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Lookup chat room member key`}</span>{`
ethree.findUser(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"bobUID"`}</span>{`).addCallback(findUsersListener)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// steve - sender identity`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` sender = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'steve'`}</span>{`;

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Get sender card with public key`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` senderCard = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.findUsers(sender);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt text and ensure it was written by sender`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decryptedText = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authDecrypt(encryptedText, senderCard);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt encoded data and ensure it was written by sender`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decryptedData1 = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authDecrypt({ `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`value`}</span>{`: encryptedText, `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`encoding`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'base64'`}</span>{` }, senderCard);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt binary data and ensure it was written by sender`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decryptedData2 = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authDecrypt(encryptedData2, senderCard);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L207",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L207"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Find user`}</span>{`
eThree.findUsers(with: [bobUID]) { users, error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` users = users, error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling here`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt text and verify if it was really written by Bob`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` originText = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! eThree.authDecrypt(text: encryptedText, from: users[bobUID]!)
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// bob - sender identity`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`String`}</span>{` sender = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'bob'`}</span>{`;

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Get sender card with public key`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` senderCard = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.findUser(sender, `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`true`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decrypt binary data and ensure it was written by sender`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`String`}</span>{` data = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.authDecrypt(data, senderCard);`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      