import React from 'react';

export interface TabProps extends React.HTMLAttributes<HTMLDivElement> {
	index: React.ReactText;
	title: string;
	children: React.ReactNode;
	onMount?(index: React.ReactText): void;
}

export const Tab: React.FC<TabProps> = ({ onMount, title, index, ...rest }: TabProps) => {
	return <div {...rest} />;
};
