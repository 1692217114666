import BackupSnippet from "snippets/e3kit/common/backup";
import BackupExtraSnippet from "snippets/e3kit/common/backup-extra";
import RestoreSnippet from "snippets/e3kit/common/restore";
import RestoreExtraSnippet from "snippets/e3kit/common/restore-extra";
import ChangeBackupPwdSnippet from "snippets/e3kit/common/change-password";
import ChangeBackupPwdNameSnippet from "snippets/e3kit/common/change-password-keyname";
import DeleteBackupSnippet from "snippets/e3kit/common/reset-backup";
import DeriveSnippet from "snippets/e3kit/common/derive";
import DeleteKeyNameSnippet from "snippets/e3kit/common/reset-backup-keyname";
import * as React from 'react';
export default {
  BackupSnippet,
  BackupExtraSnippet,
  RestoreSnippet,
  RestoreExtraSnippet,
  ChangeBackupPwdSnippet,
  ChangeBackupPwdNameSnippet,
  DeleteBackupSnippet,
  DeriveSnippet,
  DeleteKeyNameSnippet,
  React
};