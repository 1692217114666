import React from 'react';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';
import styles from './navigation.module.css';
import { Link } from 'gatsby';
import sideMenu, { MenuItem } from 'content/SideMenu.yml';

export interface CardLinkProps {
	title: string;
	href: string;
}

function findMenuItem<T extends MenuItem>(recursiveArray: T[], findFunction: (obj: T) => boolean) {
	const result = recursiveArray.find(findFunction);
	if (result) return result;

	let i = 0;
	const ARR_LENGTH = recursiveArray.length;

	while (i < ARR_LENGTH) {
		const children = recursiveArray[i].children;
		if (children) {
			const result = findMenuItem(children, findFunction);
			if (result) {
				return { title: recursiveArray[i].title, upLevel: result };
			}
		}
		i++;
	}

	return undefined;
}

interface BreadCrumbsProps {
	title: string;
	upLevel?: BreadCrumbsProps;
}

function joinCrumbsToStr(breadCrumbs: BreadCrumbsProps): string {
	if (breadCrumbs.upLevel) {
		return breadCrumbs.title + ' > ' + joinCrumbsToStr(breadCrumbs.upLevel);
	}
	return breadCrumbs.title;
}

const CardLink: React.FC<CardLinkProps> = props => {
	const getCrumbs = (href: string): string => {
		const menuItems = findMenuItem(sideMenu, i => {
			return i.link === href;
		});

		if (menuItems) {
			return joinCrumbsToStr(menuItems);
		}
		return '';
	};

	return (
		<Link className={styles.container} to={props.href}>
			<ArrowRight className={styles.arrowIcon} />
			<div className={styles.textSection}>
				<span className={styles.topSection}>{props.title}</span>
				<span className={styles.bottomSection}>{getCrumbs(props.href)}</span>
			</div>
		</Link>
	);
};

export default CardLink;
