import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`PureContext context = PureContext.createContext(
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AT.znqRZcOdzybj62Rzer897pX2DZ9KZoYF"`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"NM.Qs0YFBS7Mr3Aaa9qn4OCVcNC1wvPCXfUpZNWFCPUOUI="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"BU.MCowBQYDK2VwAyEAKWSBAZbbekJ5Ns36jRcjEiut3rH4rpaCCVnRxcB+d/E="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SK.1.3JE4SAOXGC95nDOjOhCyTXZqdQmjaqz9mtZhIN9s/2g=.cFGlNRis9FQ77DW2fCpBcx+EI4SHv7guS1sPCnSRmx0=.rKEuUZePvcr6CdOTJkk0kd0kUAq1rSx0x2A9lUegQHo="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"PK.1.BCIeyCW9xDfbuYQb6CIJ7dMqujrjBlIysJs6dXxV+9FU9kxC60fD1dl/P/6TT2wJN8p9E16IPNt5OdJ6Tq3S6L0=.BGjcd5b4wzuGapBQXcUxVXnHWvscYMDbTWk1zxqejSruppcoVWzeYlF0z7GTT3HUdejdrFtbL6sRxD/wOu5jYAs="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"UT.2.CiDd8vnWg2I5nzcCMbd0f72fcYPGgWoNnC48ZUBaiiX66hIgx9y+4+7wgwTHCk2wTK392mTQyBuzmQrZHTPAC7X+CG8=.gDHGqQ0uxqybPnuTWIP9FewlB4eq0Qff6/1VEZKtYkQ=.cr3Hs5Ej8Dz5BHt1KkHXf/Mqrqb/1/hU28W3ZwFOjJY="`}</span>{`);

Pure pure = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` Pure(context);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-php"
      }}>{`$context = PureContext::createVirgilContext(
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AT.znqRZcOdzybj62Rzer897pX2DZ9KZoYF"`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"NM.Qs0YFBS7Mr3Aaa9qn4OCVcNC1wvPCXfUpZNWFCPUOUI="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"BU.MCowBQYDK2VwAyEAKWSBAZbbekJ5Ns36jRcjEiut3rH4rpaCCVnRxcB+d/E="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SK.1.3JE4SAOXGC95nDOjOhCyTXZqdQmjaqz9mtZhIN9s/2g=.cFGlNRis9FQ77DW2fCpBcx+EI4SHv7guS1sPCnSRmx0=.rKEuUZePvcr6CdOTJkk0kd0kUAq1rSx0x2A9lUegQHo="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"PK.1.BCIeyCW9xDfbuYQb6CIJ7dMqujrjBlIysJs6dXxV+9FU9kxC60fD1dl/P/6TT2wJN8p9E16IPNt5OdJ6Tq3S6L0=.BGjcd5b4wzuGapBQXcUxVXnHWvscYMDbTWk1zxqejSruppcoVWzeYlF0z7GTT3HUdejdrFtbL6sRxD/wOu5jYAs="`}</span>{`);

$context->setUpdateToken(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"UT.2.CiDd8vnWg2I5nzcCMbd0f72fcYPGgWoNnC48ZUBaiiX66hIgx9y+4+7wgwTHCk2wTK392mTQyBuzmQrZHTPAC7X+CG8=.gDHGqQ0uxqybPnuTWIP9FewlB4eq0Qff6/1VEZKtYkQ=.cr3Hs5Ej8Dz5BHt1KkHXf/Mqrqb/1/hU28W3ZwFOjJY="`}</span>{`);

$pure = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` Pure($context);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-go"
      }}>{`context, err := purekit.CreateDefaultCloudContext(
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AT.znqRZcOdzybj62Rzer897pX2DZ9KZoYF"`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"NM.Qs0YFBS7Mr3Aaa9qn4OCVcNC1wvPCXfUpZNWFCPUOUI="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"BU.MCowBQYDK2VwAyEAKWSBAZbbekJ5Ns36jRcjEiut3rH4rpaCCVnRxcB+d/E="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SK.1.3JE4SAOXGC95nDOjOhCyTXZqdQmjaqz9mtZhIN9s/2g=.cFGlNRis9FQ77DW2fCpBcx+EI4SHv7guS1sPCnSRmx0=.rKEuUZePvcr6CdOTJkk0kd0kUAq1rSx0x2A9lUegQHo="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"PK.1.BCIeyCW9xDfbuYQb6CIJ7dMqujrjBlIysJs6dXxV+9FU9kxC60fD1dl/P/6TT2wJN8p9E16IPNt5OdJ6Tq3S6L0=.BGjcd5b4wzuGapBQXcUxVXnHWvscYMDbTWk1zxqejSruppcoVWzeYlF0z7GTT3HUdejdrFtbL6sRxD/wOu5jYAs="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"UT.2.CiDd8vnWg2I5nzcCMbd0f72fcYPGgWoNnC48ZUBaiiX66hIgx9y+4+7wgwTHCk2wTK392mTQyBuzmQrZHTPAC7X+CG8=.gDHGqQ0uxqybPnuTWIP9FewlB4eq0Qff6/1VEZKtYkQ=.cr3Hs5Ej8Dz5BHt1KkHXf/Mqrqb/1/hU28W3ZwFOjJY="`}</span>{`)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//TODO process error`}</span>{`
}`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      