import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Listener for findUsers`}</span>{`
OnResultListener<FindUsersResult> findUsersListener =
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnResultListener<FindUsersResult>() {
                    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(FindUsersResult findUsersResult)`}</span>{` `}</span>{`{
                        String text = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"I was text but became byte array"`}</span>{`;
                        Data data = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` Data(text.getBytes());

                        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt data using user public keys`}</span>{`
                        Data encryptedData = eThree.authEncrypt(data, findUsersResult);

                        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt message using user public keys`}</span>{`
                        String encryptedText = eThree.authEncrypt(text, findUsersResult);
                    }

                    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{

                    }
                };

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Lookup destination user public keys`}</span>{`
List<String> identities = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` ArrayList<>(`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`3`}</span>{`);
identities.add(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"userUID1"`}</span>{`);
identities.add(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"userUID2"`}</span>{`);
identities.add(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"userUID3"`}</span>{`);
ethree.findUsers(identities).addCallback(findUsersListener);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Listener for findUsers`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` findUsersListener =
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnResultListener<FindUsersResult> {
            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(findUsersResult: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`FindUsersResult`}</span>{`)`}</span></span>{` {
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` text = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"I was text but became byte array"`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{` = Data(text.toByteArray())

                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt data using user public keys`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedData = ethree.authEncrypt(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{`, findUsersResult)

                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt message using user public keys`}</span>{`
                `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedText = ethree.authEncrypt(text, findUsersResult)
            }

            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
            }
        }

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Find destination user Cards`}</span>{`
ethree.findUsers(listOf(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"userUID1"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"userUID2"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"userUID3"`}</span>{`)).addCallback(findUsersListener)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// alice and bob - recipient identities`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// steve - sender identity`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` identities = [`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alice'`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'bob'`}</span>{`];

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Find users cards with public keys`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` findUsersResult = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.findUsers(identities);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt text string`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` encryptedText = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authEncrypt(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'message'`}</span>{`, findUsersResult);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt encoded data`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` encryptedData1 = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authEncrypt(
  { `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`value`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'bWVzc2FnZQ=='`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`encoding`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'base64'`}</span>{` },
  findUsersResult
);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt binary data`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` encryptedData2 = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authEncrypt(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`Uint8Array`}</span>{`(), findUsersResult);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L183",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L183"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// prepare a message`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` messageToEncrypt = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Alice and Den!"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` identities = [`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Den"`}</span>{`]

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Search user's Cards to encrypt for`}</span>{`
eThree!.findUsers(with: identities) { findUsersResult, error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` findUsersResult = findUsersResult, error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling here`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// encrypt text`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedMessage = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! eThree.authEncrypt(text: messageToEncrypt, `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`for`}</span>{`: findUsersResult)
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`String`}</span>{` messageToEncrypt = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Alice and Den!"`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` users = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.findUsers([`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Den"`}</span>{`], `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`true`}</span>{`);
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` encryptedData = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.authEncrypt(users, messageToEncrypt);
} `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`on`}</span>{` PlatformException `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (e) {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      