import GroupCreateSnippet from "snippets/e3kit/common/group/create";
import GroupLoadSnippet from "snippets/e3kit/common/group/load";
import GroupGetSnippet from "snippets/e3kit/common/group/get";
import GroupEncryptSnippet from "snippets/e3kit/common/group/encrypt";
import GroupDecryptSnippet from "snippets/e3kit/common/group/decrypt";
import GroupAddSnippet from "snippets/e3kit/common/group/add";
import GroupDeleteSnippet from "snippets/e3kit/common/group/delete";
import GroupRemoveSnippet from "snippets/e3kit/common/group/remove";
import GroupUpdateSnippet from "snippets/e3kit/common/group/update";
import GroupLargeFileSnippet from "snippets/e3kit/common/group/large-file";
import * as React from 'react';
export default {
  GroupCreateSnippet,
  GroupLoadSnippet,
  GroupGetSnippet,
  GroupEncryptSnippet,
  GroupDecryptSnippet,
  GroupAddSnippet,
  GroupDeleteSnippet,
  GroupRemoveSnippet,
  GroupUpdateSnippet,
  GroupLargeFileSnippet,
  React
};