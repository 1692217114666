import InstallE3KitIos from "common/e3kit/install/platforms/ios";
import InstallE3KitAndroid from "common/e3kit/install/platforms/android";
import InstallE3KitJSWeb from "common/e3kit/install/platforms/js-web";
import InstallE3KitNodejs from "common/e3kit/install/platforms/nodejs";
import InstallE3KitReactNative from "common/e3kit/install/platforms/react-native";
import InstallE3KitFlutter from "common/e3kit/install/platforms/flutter";
import * as React from 'react';
export default {
  InstallE3KitIos,
  InstallE3KitAndroid,
  InstallE3KitJSWeb,
  InstallE3KitNodejs,
  InstallE3KitReactNative,
  InstallE3KitFlutter,
  React
};