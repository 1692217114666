import InstallCoreCSharp from "common/core/install/languages/cs";
import InstallCoreGo from "common/core/install/languages/go";
import InstallCoreJava from "common/core/install/languages/java";
import InstallCoreJS from "common/core/install/languages/js";
import InstallCorePHP from "common/core/install/languages/php";
import InstallCorePython from "common/core/install/languages/python";
import InstallCoreRuby from "common/core/install/languages/ruby";
import * as React from 'react';
export default {
  InstallCoreCSharp,
  InstallCoreGo,
  InstallCoreJava,
  InstallCoreJS,
  InstallCorePHP,
  InstallCorePython,
  InstallCoreRuby,
  React
};