import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Prepare streams.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` plaintext = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{` = plaintext.toByteArray()
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` inputStream = ByteArrayInputStream(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{`)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` inputStreamSize = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{`.size
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedOutputStream = ByteArrayOutputStream()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Encrypt stream.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` streamKeyData = aliceEthree.encryptShared(inputStream, inputStreamSize, encryptedOutputStream)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Upload data from \`encryptedOutputStream\` to a remote storage.`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
* Application specific code.
*/`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4. Encrypt \`streamKeyData\` to a specific user (peer-to-peer).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` bobCard = aliceEthree.findUser(bobIdentity).`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`()
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` p2pEncryptedStreamKeyData = aliceEthree.authEncrypt(Data(streamKeyData), bobCard)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Send encrypted \`streamKeyData\` (p2pEncryptedStreamKeyData, or groupEncryptedStreamKeyData) to destination device.`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
* Application specific code.
*/`}</span></code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-js",
        "metastring": "tabname=JS Browser",
        "tabname": "JS Browser"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Prepare file.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` file = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` File([`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'1. Programs Offered by...'`}</span>{`], `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alice_reports.txt'`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or get from input`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` file = `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`document`}</span>{`.getElementById(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'file-input'`}</span>{`).files[`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0`}</span>{`];

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Encrypt file.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` { encryptedSharedFile, fileKey } = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` alice.encryptSharedFile(file);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Download \`encryptedSharedFile\``}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4. Encrypt \`fileKey\` for a specific user (peer-to-peer).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` p2pEncryptedFileKey = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` alice.authEncrypt(fileKey);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Send encrypted \`encryptedFileKey\` (p2pEncryptedFileKey, or encryptedFileKeyForGroup) to destination device.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span></code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Prepare streams.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` plaintext = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` data = plaintext.data(using: .utf8)!
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` inputStream = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`InputStream`}</span>{`(data: data)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` inputStreamSize = data.`}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`count`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedOutputStream = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`OutputStream`}</span>{`.toMemory()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Encrypt stream.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` streamKeyData = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.encryptShared(inputStream, streamSize: inputStreamSize, to: encryptedOutputStream)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Upload data from \`encryptedOutputStream\` to a remote storage.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4 Encrypt \`streamKeyData\` for a specific user (peer-to-peer).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` p2pEncryptedStreamKeyData = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.authEncrypt(data: streamKeyData)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Send encrypted \`streamKeyData\` (p2pEncryptedStreamKeyData, or groupEncryptedStreamKeyData) to destination device.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      