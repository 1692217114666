import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`ethree.deleteTemporaryChannel(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Bob"`}</span>{`)
      .addCallback(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnCompleteListener() {
          `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span>{` `}</span>{`{
              `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Channel was deleted!`}</span>{`
          }

          `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
              `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
          }
      });`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}>{`ethree.deleteTemporaryChannel(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Bob"`}</span>{`)
        .addCallback(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnCompleteListener {
            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span></span>{` {
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Channel was deleted!`}</span>{`
            }

            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
                `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
            }
        })`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}>{`eThree.deleteTemporaryChannel(with: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Bob"`}</span>{`) { error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Channel was deleted!`}</span>{`
}`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      