import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="Standard" mdxType="LanguageTab">
        <p>{`Package is available for:`}</p>
        <ul>
          <li parentName="ul">{`Android API 21+`}</li>
        </ul>
        <p>{`To integrate E3Kit into your Android project using Gradle, add to dependencies in your `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{}}>{`    implementation 'com.virgilsecurity:ethree:<latest-version>'
`}</code></pre>
        <p>{`The `}<strong parentName="p">{`<latest-version`}{`>`}</strong>{` of the SDK can be found in the `}<a parentName="p" {...{
            "href": "https://mvnrepository.com/artifact/com.virgilsecurity/ethree"
          }}>{`Maven Central Repository`}</a></p>
  </LanguageTab>
  <LanguageTab language="Enclave" mdxType="LanguageTab">
        <p>{`Package is available for:`}</p>
        <ul>
          <li parentName="ul">{`Android API 23+ is required.`}</li>
        </ul>
        <p>{`This `}<inlineCode parentName="p">{`e3kit-enclave`}</inlineCode>{` module uses `}<a parentName="p" {...{
            "href": "https://developer.android.com/training/articles/keystore"
          }}>{`Android Keystore`}</a>{` to store symmetric key which is used to encrypt `}<inlineCode parentName="p">{`e3kit`}</inlineCode>{` user's local private key.
If you use this module after using Standard E3kit module, all local private keys will be migrated to Android Keystore, so you won't be able to use them with Standard E3kit module any more. (You can migrate them to Standard E3kit module on your own if needed)`}</p>
        <p>{`To integrate E3Kit SDK into your Android project using Gradle, set up dependencies in your `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{}}>{`    implementation 'com.virgilsecurity:ethree-enclave:<latest-version>'
`}</code></pre>
        <p>{`The `}<strong parentName="p">{`<latest-version`}{`>`}</strong>{` of the SDK can be found in the `}<a parentName="p" {...{
            "href": "https://mvnrepository.com/artifact/com.virgilsecurity/ethree"
          }}>{`Maven Central Repository`}</a>{`.`}</p>
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      