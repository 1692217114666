import PureKitChangePasswordSnippet from "snippets/purekit/password-encryption/change-password";
import PureKitResetSnippet from "snippets/purekit/password-encryption/reset";
import PureKitRecoverSnippet from "snippets/purekit/password-encryption/recover";
import PureKitDeleteUserSnippet from "snippets/purekit/password-encryption/delete-user";
import PureKitDeleteKeySnippet from "snippets/purekit/password-encryption/delete-key";
import * as React from 'react';
export default {
  PureKitChangePasswordSnippet,
  PureKitResetSnippet,
  PureKitRecoverSnippet,
  PureKitDeleteUserSnippet,
  PureKitDeleteKeySnippet,
  React
};