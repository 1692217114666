import React from 'react';
import classnames from 'classnames';
import styles from './ToggleButton.module.css';

interface ToggleButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
	isChecked: boolean;
	isDotted?: boolean;
}

const ToggleButton: React.FC<ToggleButtonProps> = props => {
	const className = classnames(styles.toggleButton, props.className, {
		[styles.checked]: props.isChecked,
		[styles.dotted]: props.isDotted,
	});

	return (
		<button className={className} onClick={props.onClick}>
			<span className={styles.toggleButtonIcon}>
				<span data-icon className={styles.iconBar} />
				<span data-icon className={styles.iconBar} />
				<span data-icon className={styles.iconBar} />
			</span>
		</button>
	);
};

export default ToggleButton;
