import React from 'react';
import styles from './DocsMenu.module.css';
import DocsMenuLink from './DocsMenuLink';
import DocsMenuContent from './DocsMenuContent';
import { MenuItem } from '*/SideMenu.yml';

export interface DocsMenuExpandingItemProps {
	item: MenuItem;
	depth: number;
	isOpen: boolean;
	isActive: boolean;
	setOpen: (b: boolean) => void;
}

const DocsMenuExpandingItem: React.FC<DocsMenuExpandingItemProps> = props => {
	const { item, isOpen, depth } = props;
	return (
		<details open={isOpen}>
			<summary className={styles.Summary}>
				<DocsMenuLink {...props} />
			</summary>
			{item.children && <DocsMenuContent items={item.children} depth={depth + 1} />}
		</details>
	);
};

export default DocsMenuExpandingItem;
