/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'components/Tabs';
import styles from './LanguageTabs.module.css';

const LanguageTabs: React.FC = ({ children }) => {
	const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(undefined);
	const selectLanguage = (language: string) => {
		setSelectedLanguage(language);
	};

	useEffect(() => {
		if (!selectedLanguage && children && children[0]) {
			setSelectedLanguage(children[0].props.language);
		}
	}, []);

	return (
		<Tabs selectedIndex={selectedLanguage} onChange={selectLanguage} tabClass={styles.Content}>
			{React.Children.map(children, (child: any) => {
				const language = child.props.language;
				return (
					<Tab index={language} title={language}>
						{child}
					</Tab>
				);
			})}
		</Tabs>
	);
};

export default LanguageTabs;
