import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java",
        "metastring": "source=https://github.com/VirgilSecurity/virgil-purekit-kotlin/blob/31c6972d638953ff3df1d254013916fa72502b9b/purekit/src/main/kotlin/com/virgilsecurity/purekit/pure/storage/mariadb/MariaDbPureStorage.java#L65",
        "source": "https://github.com/VirgilSecurity/virgil-purekit-kotlin/blob/31c6972d638953ff3df1d254013916fa72502b9b/purekit/src/main/kotlin/com/virgilsecurity/purekit/pure/storage/mariadb/MariaDbPureStorage.java#L65"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`public`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-class"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`class`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`CustomPureStorage`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`implements`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`PureStorage`}</span>{`, `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`PureModelSerializerDependent`}</span>{` `}</span>{`{ ... }`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-php"
      }}><span parentName="code" {...{
          "className": "hljs-class"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`class`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`CustomPureStorage`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`implements`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`PureStorage`}</span>{`, `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`PureModelSerializerDependent`}</span>{` `}</span>{`{ ... }`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-go",
        "metastring": "source=https://github.com/VirgilSecurity/virgil-purekit-go/blob/5b3d1d1080411df05fc6b999ea2141a867157ad4/storage/mariadbpurestorage.go#L55",
        "source": "https://github.com/VirgilSecurity/virgil-purekit-go/blob/5b3d1d1080411df05fc6b999ea2141a867157ad4/storage/mariadbpurestorage.go#L55"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`type`}</span>{` CustomDBPureStorage `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`struct`}</span>{` {
	db         *sqlx.DB
	Serializer *ModelSerializer
}
...`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      