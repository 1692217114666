import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`OnCompleteListener backupListener = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnCompleteListener() {
    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// You're done`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }
};

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Backup user's private key to the cloud (encrypted using her password).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This will enable your user to log in from another device and have access`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to the same private key there.`}</span>{`
eThree.backupPrivateKey(keyPassword).addCallback(backupListener);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L155",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L155"
      }}>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.backupPrivateKey(keyPassword);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
eThree.backupPrivateKey(keyPassword)
    .then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}>{`() =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.log(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'success'`}</span>{`))
    .catch(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`e`}</span>{` =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.error(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'error: '`}</span>{`, e));`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` backupListener =
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnCompleteListener {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span></span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// private key backup success`}</span>{`
        }

        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
        }
    }

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Backup user's private key to the cloud (encrypted using her password).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This will enable your user to log in from another device and have access`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to the same private key there.`}</span>{`
eThree.backupPrivateKey(keyPassword).addCallback(backupListener)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/c2c59f31f36d3a7d1e7ee4807114478373bf3b32/E3Kit%20iOS%20Swift%20Sample/Device.swift#L231",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/c2c59f31f36d3a7d1e7ee4807114478373bf3b32/E3Kit%20iOS%20Swift%20Sample/Device.swift#L231"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Backup user's private key to the cloud (encrypted using her password).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This will enable your user to log in from another device and have access`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to the same private key there.`}</span>{`
eThree.backupPrivateKey(password: keyPassword) { error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{` {
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling here`}</span>{`
    }
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Backup user's private key to the cloud (encrypted using her password).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This will enable your user to log in from another device and have access`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// to the same private key there.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.backupPrivateKey(keyPassword);
} `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`on`}</span>{` PlatformException `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (e) {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
}`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      