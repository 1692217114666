import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`byte`}</span>{`[] cipherText = pure.encrypt(userId, dataId, plainText)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-php"
      }}>{`$cipherText = $pure->encrypt($userId, $dataId, $plainText)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-go"
      }}>{`cipherText, err := pure.Encrypt(userId, dataId, plainText)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      