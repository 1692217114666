import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Fetch Virgil JWT token from Firebase function`}</span>{`
OnGetTokenCallback tokenCallback = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnGetTokenCallback() {

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@NotNull`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` String `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onGetToken`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span>{` `}</span>{`{
        Map<String, String> data =
                (Map<String, String>) FirebaseFunctions.getInstance()
                                                       .getHttpsCallable(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"getVirgilJwt"`}</span>{`)
                                                       .call()
                                                       .getResult()
                                                       .getData();

        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` data.get(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"token"`}</span>{`);
    }
};

OnResultListener<EThree> initializeListener = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnResultListener<EThree>() {

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(EThree result)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Init done!`}</span>{`
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Save the eThree instance`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }
};

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Initialize EThree SDK with JWT token from Firebase Function`}</span>{`
EThree.initialize(context, tokenCallback).addCallback(initializeListener);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Fetch Virgil JWT token from Firebase function`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` tokenCallback =
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnGetTokenCallback {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onGetToken`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span></span>{`: String {
             `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{` = FirebaseFunctions.getInstance()
                   .getHttpsCallable(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"getVirgilJwt"`}</span>{`)
                   .call()
                   .result
                   ?.`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`as`}</span>{` Map<String, String>

            `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{`[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"token"`}</span>{`]
        }
    }

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` initializeListener =
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnResultListener<EThree> {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(result: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`EThree`}</span>{`)`}</span></span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Init done!`}</span>{`
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Save the eThree instance`}</span>{`
        }

        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
        }
    }

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Initialize EThree SDK with JWT token from Firebase Function`}</span>{`
EThree.initialize(context, tokenCallback).addCallback(initializeListener)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` firebase `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'firebase'`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'@virgilsecurity/e3kit-browser'`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or 'e3kit-native'`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` getToken = firebase.functions().httpsCallable(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'getVirgilJwt'`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` initializeFunction = `}<span parentName="code" {...{
          "className": "hljs-function"
        }}>{`() =>`}</span>{` getToken().then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`result`}</span>{` =>`}</span>{` result.data.token);
EThree.initialize(initializeFunction).then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`eThree`}</span>{` =>`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Initialize done`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Save the eThree instance`}</span>{`
}).catch(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`error`}</span>{` =>`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` code = error.code;
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// code === 'unauthenticated' if user not authenticated`}</span>{`
});`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` FirebaseFunctions
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` VirgilE3Kit

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This function makes authenticated request to Firebase function`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// The token it returns serves to make authenticated requests to Virgil Cloud`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` getToken = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Functions`}</span>{`.functions().httpsCallable(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"getVirgilJwt"`}</span>{`)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` tokenCallback: `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThree`}</span>{`.`}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`RenewJwtCallback`}</span>{` = { completion `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
    getToken.call { result, error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` data = result?.data `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`as`}</span>{`? [`}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`String`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`String`}</span>{`]
        completion(data?[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"token"`}</span>{`], error)
    }
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Initialize EThree SDK with get token callback to Firebase Function`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// E3kit uses the identity encoded in the JWT as the current user's identity`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` eThree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThree`}</span>{`(identity: identity, tokenCallback: tokenCallback)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// not supported yet`}</span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      