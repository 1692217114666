import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// generate a new symmetric key and encrypt the file with it`}</span>{`

VirgilCrypto crypto = new VirgilCrypto();
byte[] yourFile;
byte[] encryptedFile;
byte[] key = crypto.generateRandomData(`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`44`}</span>{`); `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 32-byte key + 12-byte nonce`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` (Aes256Gcm aes256Gcm = new Aes256Gcm()) {
    aes256Gcm.setKey(Arrays.copyOfRange(key, `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0`}</span>{`, aes256Gcm.getKeyLen()));
    aes256Gcm.setNonce(Arrays.copyOfRange(key, aes256Gcm.getKeyLen(), aes256Gcm.getKeyLen() + aes256Gcm.getNonceLen()));
    AuthEncryptAuthEncryptResult authEncryptResult = aes256Gcm.authEncrypt(yourFile, new byte[`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0`}</span>{`]);
    encryptedFile = concat(authEncryptResult.getOut(), authEncryptResult.getTag());
}
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (FoundationException e) {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`///`}</span>{`
}

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`private`}</span>{` byte[] concat(byte[] body1, byte[] body2) {
    byte[] body = new byte[body1.length + body2.length];
    System.arraycopy(body1, `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0`}</span>{`, body, `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0`}</span>{`, body1.length);
    System.arraycopy(body2, `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0`}</span>{`, body, body1.length, body2.length);
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` body;
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//`}<span parentName="span" {...{
            "className": "hljs-doctag"
          }}>{`TODO:`}</span>{` upload the encrypted file to any storage and send the symmetric key with the encrypted file URL to the group (step 3, 4, 5)`}</span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      