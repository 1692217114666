import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "install-e3kit"
    }}>{`Install E3Kit`}</h4>
    <p>{`Installation of E3Kit package is pretty simple and it consists from 3 steps (2 if you develop for iOS only):`}</p>
    <ol>
      <li parentName="ol">{`Update your `}<inlineCode parentName="li">{`pubspec.yaml`}</inlineCode>{` by adding a virgil_e3kit dependency into dependencies section:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "hljs language-yaml"
      }}>{`  `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`dependencies:`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`flutter:`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`sdk:`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`flutter`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`virgil_e3kit:`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`0.0`}</span><span parentName="code" {...{
          "className": "hljs-number"
        }}>{`.1`}</span></code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`flutter pub get`}</inlineCode>{` to download your dependencies (`}<inlineCode parentName="p">{`pubspec.lock`}</inlineCode>{` will be updated).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For an Android `}<inlineCode parentName="p">{`emulator`}</inlineCode>{` or a `}<inlineCode parentName="p">{`device`}</inlineCode>{`, to run your code you have to update:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`android/app/build.gradle`}</inlineCode>{` (set `}<inlineCode parentName="li">{`minSdkVersion`}</inlineCode>{` to `}<inlineCode parentName="li">{`21`}</inlineCode>{`)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`android/app/src/main/AndroidManifest.xml`}</inlineCode>{` by adding a few rows to manifest section:`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "hljs language-xml"
          }}>{`  xmlns:tools="http://schemas.android.com/tools"
  tools:replace="android:label"
  android:label="\${YOUR_APP_NAME}"`}</code></pre>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`\${YOUR_APP_NAME}`}</inlineCode>{` - has to be your actual project name.`}</li>
          <li parentName="ul">{`then, remove all other `}<inlineCode parentName="li">{`android:label`}</inlineCode>{` entries from manifest file.`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      