import React from 'react';
import { FadeAnimation } from '../../lib/FadeAnimation';
import { ReactComponent as IconShield } from 'icons/Icon-Shield.svg';
import { ReactComponent as IconBook } from 'icons/Icon-Book.svg';
import { ReactComponent as IconHelp } from 'icons/Icon-Help.svg';
import { ReactComponent as IconGrid } from 'icons/Icon-Grid.svg';

import locale from './MainPanel.en';
import styles from './MainPanel.module.css';
import classNames from 'classnames';
import { Link } from 'gatsby';

export interface MainPanelProps {
	bottomChildren?: React.ReactNode;
	className?: string;
}

const MainPanel: React.FC<MainPanelProps> = ({ bottomChildren, className, children }) => {
	return (
		<div className={classNames(styles.Container, className)}>
			<FadeAnimation component="div" className={styles.TopGroup}>
				<a
					id="website-link"
					target="_blank"
					rel="noopener noreferrer"
					href="https://virgilsecurity.com/"
					title={locale.menu.website}
					className={styles.MenuLink}
				>
					<IconShield />
				</a>
				<Link
					to={'/'}
					className={classNames(styles.DocsMenuLink, styles.Active)}
					title={locale.menu.documentation}
				>
					<IconBook className={styles.Icon} />
				</Link>
				<a
					href="https://dashboard.virgilsecurity.com"
					className={styles.MenuLink}
					title={locale.menu.applications}
				>
					<IconGrid className={styles.Icon} />
				</a>
				{children}
			</FadeAnimation>
			<FadeAnimation component="div" className={styles.BottomGroup}>
				{bottomChildren}
				<a
					id="support-button"
					href={locale.supportLink}
					title={locale.menu.support}
					className={styles.MenuLink}
				>
					<IconHelp className={styles.PopupIcon} />
				</a>
			</FadeAnimation>
		</div>
	);
};

export default MainPanel;
