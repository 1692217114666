import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InstallCoreCSharp from "common/core/install/languages/cs";
import InstallCoreGo from "common/core/install/languages/go";
import InstallCoreJava from "common/core/install/languages/java";
import InstallCoreJS from "common/core/install/languages/js";
import InstallCorePHP from "common/core/install/languages/php";
import InstallCorePython from "common/core/install/languages/python";
import InstallCoreRuby from "common/core/install/languages/ruby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LanguageTabs mdxType="LanguageTabs">
 <LanguageTab language="Node.Js" mdxType="LanguageTab">
        <InstallCoreJS mdxType="InstallCoreJS" />
 </LanguageTab>
 <LanguageTab language="Java" mdxType="LanguageTab">
        <InstallCoreJava mdxType="InstallCoreJava" />
 </LanguageTab>
 <LanguageTab language="Go" mdxType="LanguageTab">
        <InstallCoreGo mdxType="InstallCoreGo" />
 </LanguageTab>
 <LanguageTab language="C#/.NET" mdxType="LanguageTab">
        <InstallCoreCSharp mdxType="InstallCoreCSharp" />
 </LanguageTab>
 <LanguageTab language="PHP" mdxType="LanguageTab">
        <InstallCorePHP mdxType="InstallCorePHP" />
 </LanguageTab>
 <LanguageTab language="Python" mdxType="LanguageTab">
        <InstallCorePython mdxType="InstallCorePython" />
 </LanguageTab>
 <LanguageTab language="Ruby" mdxType="LanguageTab">
        <InstallCoreRuby mdxType="InstallCoreRuby" />
 </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      