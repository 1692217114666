import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Receive \`encryptedStreamKeyData\` and download data from the remote storage.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
* Application specific code.
*/`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Prepare streams.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedInputStream = ByteArrayInputStream(encryptedData)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedOutputStream = ByteArrayOutputStream()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Find initiator's Card.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` aliceCard = bobEthree.findUser(aliceIdentity).`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4. Decrypt \`encryptedStreamKeyData\` received by the group.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` groupId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"group-chat-1"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` bobGroup = bobEthree.loadGroup(groupId, aliceCard).`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`() `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// load correspond group`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` groupDecryptedStreamKeyData = bobGroup.decrypt(groupEncryptedStreamKeyData, aliceCard) `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// decrypt key`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Decrypt stream.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` decryptedStreamKeyData = p2pDecryptedStreamKeyData ?: groupDecryptedStreamKeyData

bobEthree.decryptShared(encryptedInputStream, decryptedOutputStream, decryptedStreamKeyData, aliceCard)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "tabname=JS Browser",
        "tabname": "JS Browser"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Receive \`encryptedSharedFile\`.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span>{`

 `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Find initiator's Card.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` aliceCard = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` bob.findUsers(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"alice@myapp.com"`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3.2 Decrypt \`encryptedFileKeyForGroup\` received by the group.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` groupId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'unique_group_id'`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` bobGroup = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.loadGroup(groupId, aliceCard);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decryptedFileKey = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` bobGroup.decrypt(encryptedFileKeyForGroup, aliceCard);


`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4. Decrypt file.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decryptedSharedFile = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` bob.decryptSharedFile(
    encryptedSharedFile,
    decryptedFileKey,
    aliceCard
);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1. Receive \`encryptedStreamKeyData\` and download data from the remote storage.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/**
 * Application specific code.
 */`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedData = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Data`}</span>{`()
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedStreamKeyData = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`Data`}</span>{`()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 2. Prepare streams.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedInputStream = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`InputStream`}</span>{`(data: encryptedData)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decryptedOutputStream = `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`OutputStream`}</span>{`.toMemory()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 3. Find initiator's Card.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` aliceIdentity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` aliceCard = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.findUsers(with: [aliceIdentity]).startSync().`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`().first!.value

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 4 Decrypt \`encryptedStreamKeyData\` received by the group.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` groupId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"chat"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` bobGroup = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.loadGroup(id: groupId, initiator: aliceIdentity).startSync().`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`get`}</span>{`() `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// load correspond group`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` groupDecryptedStreamKeyData = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` bobGroup.decrypt(data: encryptedStreamKeyData, from: aliceCard) `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// decrypt key`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 5. Decrypt stream.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` decryptedStreamKeyData = p2pDecryptedStreamKeyData ?? groupDecryptedStreamKeyData
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` ethree.decryptShared(encryptedInputStream, to: decryptedOutputStream, with: decryptedStreamKeyData, verifyWith: aliceCard)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      