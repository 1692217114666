import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// add KeyPairType to the EThreeParams and`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// specify post-quantum algorithm type (E3Kit uses Round5 for `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// encryption and Falcon for signature)`}</span>{`
params.keyPairType = KeyPairType.CURVE25519_ROUND5_ED25519_FALCON`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// add KeyPairType to the EThreeParams and`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// specify post-quantum algorithm type (E3Kit uses Round5 for `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// encryption and Falcon for signature)`}</span>{`
params.setKeyPairType(KeyPairType.CURVE25519_ROUND5_ED25519_FALCON);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` { EThree, KeyPairType } `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'@virgilsecurity/e3kit-browser'`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or @virgilsecurity/e3kit-node, or @virgilsecurity/e3kit-native`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize E3Kit with tokenCallback and keyPairType `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// with specified post-quantum algorithm type (E3Kit uses Round5 `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// for encryption and Falcon for signature)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` eThreePromise = EThree.initialize(tokenCallback, {
  `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`keyPairType`}</span>{`: KeyPairType.CURVE25519_ROUND5_ED25519_FALCON,
});

eThreePromise.then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`eThree`}</span>{` =>`}</span>{` { `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`/* eThree.encrypt/decrypt/lookupPublicKeys */`}</span>{`})
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` eThree = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThreePromise;`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// add KeyPairType to the EThreeParams and`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// specify post-quantum algorithm type (E3Kit uses Round5 for `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// encryption and Falcon for signature)`}</span>{`
params.keyPairType = .curve25519Round5Ed25519Falcon`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// not supported yet`}</span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      