import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`OnCompleteListener restoreListener = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` OnCompleteListener() {
    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// You're done`}</span>{`
    }

    `}<span parentName="code" {...{
          "className": "hljs-meta"
        }}>{`@Override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`public`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`void`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(`}<span parentName="span" {...{
              "className": "hljs-meta"
            }}>{`@NotNull`}</span>{` Throwable throwable)`}</span>{` `}</span>{`{
        `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
    }
};

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to restore their private key from backup in Virgil's cloud.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// * While user in session - key can be removed and restore multiply times (via cleanup/restorePrivateKey functions). To know whether private key is present on device now use hasLocalPrivateKey() function:`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!eThree.hasLocalPrivateKey()) {
    eThree.restorePrivateKey(keyPassword).addCallback(restoreListener);
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` restoreListener =
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`object`}</span>{` : OnCompleteListener {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onSuccess`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`()`}</span></span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// You're done`}</span>{`
        }

        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`override`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`fun`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`onError`}</span><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`(throwable: `}<span parentName="span" {...{
              "className": "hljs-type"
            }}>{`Throwable`}</span>{`)`}</span></span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
        }
    }

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to restore her private key from backup in Virgil Cloud.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// While user in session - key can be removed and restore multiply times (via cleanup/restorePrivateKey functions).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// To know whether private key is present on device now use hasLocalPrivateKey() function:`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!eThree.hasLocalPrivateKey()) {
    eThree.restorePrivateKey(keyPassword).addCallback(restoreListener)
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L186",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-web/blob/54b8425422cf85bf2f65918901b4a3f29c432803/device.js#L186"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` hasLocalPrivateKey = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.hasLocalPrivateKey();

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!hasLocalPrivateKey) `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.restorePrivateKey(keyPassword);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
eThree.hasLocalPrivateKey().then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`hasLocalPrivateKey`}</span>{` =>`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!hasLocalPrivateKey) eThree.restorePrivateKey(keyPassword);
});`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift",
        "metastring": "source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L251-L253",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/E3Kit%20iOS%20Swift%20Sample/Device.swift#L251-L253"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Prerequisites: current local key should be cleaned up (see EThree.cleanUp)`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Note: To know whether private key is present on device you can use hasLocalPrivateKey() function:`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! !eThree.hasLocalPrivateKey() {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to restore her private key from cloud backup`}</span>{`
    eThree.restorePrivateKey(password: keyPassword) { error `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{`
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`guard`}</span>{` error == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{` {
            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling here`}</span>{`
        }
    }
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Prerequisites: current local key should be cleaned up (see EThree.cleanUp)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Note: To know whether private key is present on device you can use hasLocalPrivateKey() function:`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`final`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`bool`}</span>{` hasLocalPrivateKey = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.hasLocalPrivateKey();

    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!hasLocalPrivateKey) {
        `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` ethree.restorePrivateKey(keyPassword);
    }
} `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`on`}</span>{` PlatformException `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`catch`}</span>{` (e) {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Error handling`}</span>{`
} `}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      