import React, { useState, useEffect } from 'react';
import styles from './ZoomImage.module.css';
import classNames from 'classnames';

export interface ZoomImageProps {
	children: React.ReactElement;
	disablezoom: boolean;
	src: string;
}

const ZoomImage: React.FC<ZoomImageProps> = ({ children, src, disablezoom }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [onOpacity, setOnOpacity] = useState(false);

	const openModal = () => {
		setIsOpen(true);
		setTimeout(() => {
			setOnOpacity(true);
		}, 200);
	};

	const closeModal = () => {
		setOnOpacity(false);
		setTimeout(() => {
			setIsOpen(false);
		}, 200);
	};

	useEffect(() => {
		document.addEventListener('keydown', closeModal);
		return () => {
			document.removeEventListener('keydown', closeModal);
		};
	}, []);
	if (disablezoom) {
		return <>{children}</>;
	}
	return (
		<>
			<span className={styles.container} onClick={openModal}>
				{children}
			</span>
			{isOpen ? (
				<span
					className={classNames(styles.modal, { [styles.active]: onOpacity })}
					onClick={closeModal}
				>
					<img className={styles.img} src={src} />
				</span>
			) : null}
		</>
	);
};

export default ZoomImage;
