import PureKitCreateRoleSnippet from "snippets/purekit/role/create-role";
import PureKitAssignRoleSnippet from "snippets/purekit/role/assign-role";
import EncryptForRoleSnippet from "snippets/purekit/role/encrypt";
import PureKitDecryptSnippet from "snippets/purekit/decrypt";
import * as React from 'react';
export default {
  PureKitCreateRoleSnippet,
  PureKitAssignRoleSnippet,
  EncryptForRoleSnippet,
  PureKitDecryptSnippet,
  React
};