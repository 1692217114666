import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`DerivedPasswords derivedPasswords = EThree.derivePasswords(userPassword);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This password should be used for backup/restore PrivateKey`}</span>{`
String backupPassword = derivedPasswords.getBackupPassword();

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This password should be used for other purposes, e.g user authorization`}</span>{`
String loginPassword = derivedPasswords.getLoginPassword();`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` derivedPasswords = EThree.derivePasswords(userPassword)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This password should be used for backup/restore PrivateKey`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` backupPassword = derivedPasswords.backupPassword

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This password should be used for other purposes, e.g user authorization`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` loginPassword = derivedPasswords.loginPassword`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// browser/NodeJS`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` { loginPassword, backupPassword } = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` EThree.derivePasswords(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'password'`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// React Native`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` { loginPassword, backupPassword } = EThree.derivePasswords(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'password'`}</span>{`);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` derivedPasswords = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! `}<span parentName="code" {...{
          "className": "hljs-type"
        }}>{`EThree`}</span>{`.derivePasswords(from: userPassword)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This password should be used for backup/restore PrivateKey`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` backupPassword = derivedPasswords.backupPassword

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// This password should be used for other purposes, e.g user authorization`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` loginPassword = derivedPasswords.loginPassword`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-dart"
      }}>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//not supported yet`}</span>{`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      