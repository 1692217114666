import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InitializeE3KitIos from "common/e3kit/initialize/platforms/ios";
import InitializeE3KitAndroid from "common/e3kit/initialize/platforms/android";
import InitializeE3KitJS from "common/e3kit/initialize/platforms/js";
import InitializeE3KitFlutter from "common/e3kit/initialize/platforms/flutter";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="iOS" mdxType="LanguageTab">
        <InitializeE3KitIos mdxType="InitializeE3KitIos" />
  </LanguageTab>
  <LanguageTab language="Android" mdxType="LanguageTab">
        <InitializeE3KitAndroid mdxType="InitializeE3KitAndroid" />
  </LanguageTab>
  <LanguageTab language="JavaScript" mdxType="LanguageTab">
        <InitializeE3KitJS mdxType="InitializeE3KitJS" />
  </LanguageTab>
  <LanguageTab language="Flutter" mdxType="LanguageTab">
        <InitializeE3KitFlutter mdxType="InitializeE3KitFlutter" />
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      