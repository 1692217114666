import React from 'react';
import styles from './Footer.module.css';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const FooterForm: React.FC = () => {
	return (
		<form
			action="https://VirgilSecurity.us13.list-manage.com/subscribe/post?u=b2d755932a192a668f143411a&amp;id=c5bb704352"
			method="post"
			id="mc-embedded-subscribe-form"
			name="mc-embedded-subscribe-form"
			className={styles.form}
			target="_blank"
			noValidate
		>
			<div id="mc_embed_signup_scroll">
				<div className={styles.mcFieldGroup}>
					<label className={styles.label} htmlFor="mce-EMAIL">
						Email
					</label>
					<input type="email" name="EMAIL" className={styles.email} id="mce-EMAIL" />
				</div>
				{/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
				<div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
					<input type="text" name="b_b2d755932a192a668f143411a_c5bb704352" tabIndex={-1} />
				</div>
				<div className={styles.clear}>
					<input
						type="submit"
						value="Subscribe"
						name="subscribe"
						id="mc-embedded-subscribe"
						className={styles.btn}
					/>
					<label className={styles.submitLabel} htmlFor="subscribe">
						Unsubscribe at any time. Read our{' '}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://virgilsecurity.com/terms-of-service/"
						>
							terms of use.
						</a>
					</label>
				</div>
			</div>
		</form>
	);
};

const Footer: React.FC = () => {
	const data = useStaticQuery(graphql`
		query GetFooter {
			mdx(fileAbsolutePath: { regex: "/.*footer/" }) {
				body
			}
		}
	`);

	return (
		<footer className={styles.container}>
			<div className={styles.leftSide}>
				<MDXRenderer>{data.mdx.body}</MDXRenderer>
			</div>
			<div className={styles.rightSide}>
				<h3>Sign up for the developer digest</h3>
				<p>
					Share your email so we can send you updates about our our toolkits and developer platform.
				</p>
				<div id="mc_embed_signup">
					<FooterForm />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
