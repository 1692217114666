import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Virgil E3Kit is distributed via Carthage and CocoaPods.`}</p>
    <p>{`Package is available for:`}</p>
    <ul>
      <li parentName="ul">{`iOS 9.0+`}</li>
      <li parentName="ul">{`macOS 10.11+`}</li>
      <li parentName="ul">{`tvOS 9.0+`}</li>
      <li parentName="ul">{`watchOS 2.0+`}</li>
    </ul>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="CocoaPods" mdxType="LanguageTab">
        <p><a parentName="p" {...{
            "href": "https://cocoapods.org/"
          }}>{`CocoaPods`}</a>{` is a dependency manager for Cocoa projects. You can install it with the following command:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-bash"
          }}>{`$ gem install cocoapods`}</code></pre>
        <p>{`To integrate Virgil E3Kit into your Xcode project using CocoaPods, specify it in your Podfile:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-bash"
          }}>{`target `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'<Your Target Name>'`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`do`}</span>{`
use_frameworks!

pod `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'VirgilE3Kit'`}</span>{`, `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'~> 2.0.0'`}</span>{`
end`}</code></pre>
        <p>{`Then, run the following command:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-bash"
          }}>{`$ pod install`}</code></pre>
  </LanguageTab>
  <LanguageTab language="Carthage" mdxType="LanguageTab">
        <p><a parentName="p" {...{
            "href": "https://github.com/Carthage/Carthage"
          }}>{`Carthage`}</a>{` is a decentralized dependency manager that builds your dependencies and provides you with binary frameworks. You can install Carthage with `}<a parentName="p" {...{
            "href": "https://brew.sh/"
          }}>{`Homebrew`}</a>{` using the following command:`}</p>
        <pre><code parentName="pre" {...{}}>{`$ brew update
$ brew install carthage
`}</code></pre>
        <p>{`To integrate VirgilE3Kit into your Xcode project using Carthage, create an empty file with name `}<em parentName="p">{`Cartfile`}</em>{` in your project's root folder and add following lines to your `}<em parentName="p">{`Cartfile`}</em></p>
        <pre><code parentName="pre" {...{}}>{`github "VirgilSecurity/virgil-e3kit-x" ~> 2.0.0
`}</code></pre>
        <h4 {...{
          "id": "linking-against-prebuilt-binaries"
        }}>{`Linking against prebuilt binaries`}</h4>
        <p>{`To link prebuilt frameworks to your app, run following command:`}</p>
        <pre><code parentName="pre" {...{}}>{`$ carthage update
`}</code></pre>
        <p>{`This will build each dependency or download a pre-compiled framework from github Releases.`}</p>
        <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="iOS/tvOS/watchOS" mdxType="LanguageTab">
            <p>{`On your application target's “General” settings tab, in the “Linked Frameworks and Libraries” section, add following frameworks from the `}<em parentName="p">{`Carthage/Build`}</em>{` folder inside your project's folder:`}</p>
            <ul>
              <li parentName="ul">{`VirgilE3Kit`}</li>
              <li parentName="ul">{`VirgilPythiaSDK`}</li>
              <li parentName="ul">{`VirgilSDK`}</li>
              <li parentName="ul">{`VirgilCrypto`}</li>
              <li parentName="ul">{`VirgilCryptoFoundation`}</li>
              <li parentName="ul">{`VirgilCryptoPythia`}</li>
              <li parentName="ul">{`VSCCommon`}</li>
              <li parentName="ul">{`VSCFoundation`}</li>
              <li parentName="ul">{`VSCPythia`}</li>
            </ul>
            <p>{`On your application targets’ “Build Phases” settings tab, click the “+” icon and choose “New Run Script Phase.” Create a Run Script in which you specify your shell (ex: /bin/sh), add the following contents to the script area below the shell:`}</p>
            <pre><code parentName="pre" {...{}}>{`/usr/local/bin/carthage copy-frameworks
`}</code></pre>
            <p>{`and add the paths to the frameworks you want to use under “Input Files”, e.g.:`}</p>
            <pre><code parentName="pre" {...{}}>{`$(SRCROOT)/Carthage/Build/iOS/VirgilE3Kit.framework
$(SRCROOT)/Carthage/Build/iOS/VirgilPythiaSDK.framework
$(SRCROOT)/Carthage/Build/iOS/VirgilSDK.framework
$(SRCROOT)/Carthage/Build/iOS/VirgilCrypto.framework
$(SRCROOT)/Carthage/Build/iOS/VirgilCryptoFoundation.framework
$(SRCROOT)/Carthage/Build/iOS/VirgilCryptoPythia.framework
$(SRCROOT)/Carthage/Build/iOS/VSCCommon.framework
$(SRCROOT)/Carthage/Build/iOS/VSCFoundation.framework
$(SRCROOT)/Carthage/Build/iOS/VSCPythia.framework
`}</code></pre>
  </LanguageTab>
  <LanguageTab language="macOS" mdxType="LanguageTab">
            <p>{`On your application target's “General” settings tab, in the “Embedded Binaries” section, drag and drop following frameworks from the Carthage/Build folder on disk:`}</p>
            <ul>
              <li parentName="ul">{`VirgilE3Kit`}</li>
              <li parentName="ul">{`VirgilPythiaSDK`}</li>
              <li parentName="ul">{`VirgilSDK`}</li>
              <li parentName="ul">{`VirgilCrypto`}</li>
              <li parentName="ul">{`VirgilCryptoFoundation`}</li>
              <li parentName="ul">{`VirgilCryptoPythia`}</li>
              <li parentName="ul">{`VSCCommon`}</li>
              <li parentName="ul">{`VSCFoundation`}</li>
              <li parentName="ul">{`VSCPythia`}</li>
            </ul>
            <p>{`Additionally, you'll need to copy debug symbols for debugging and crash reporting on macOS.`}</p>
            <p>{`On your application target’s “Build Phases” settings tab, click the “+” icon and choose “New Copy Files Phase”. Click the “Destination” drop-down menu and select “Products Directory”. For each framework, drag and drop corresponding dSYM file.`}</p>
  </LanguageTab>
        </LanguageTabs>
  </LanguageTab>
    </LanguageTabs>
    <h4 {...{
      "id": "usage"
    }}>{`Usage`}</h4>
    <pre><code parentName="pre" {...{}}>{`import VirgilE3Kit
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      