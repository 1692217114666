import React from 'react';
import ReactComment from './ReactComment';

const HideFromGoogle = ({ children }) => {
	return (
		<React.Fragment>
			<ReactComment comment="googleoff: all" />
			{children}
			<ReactComment comment="googleon: all" />
		</React.Fragment>
	);
};

export default HideFromGoogle;
