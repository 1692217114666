import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InstallPureKitGo from "common/purekit/install/languages/go";
import InstallPureKitJava from "common/purekit/install/languages/kotlin-java";
import InstallPureKitPHP from "common/purekit/install/languages/php";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="Java" mdxType="LanguageTab">
        <InstallPureKitJava mdxType="InstallPureKitJava" />
  </LanguageTab>
  <LanguageTab language="PHP" mdxType="LanguageTab">
        <InstallPureKitPHP mdxType="InstallPureKitPHP" />
  </LanguageTab>
  <LanguageTab language="Go" mdxType="LanguageTab">
        <InstallPureKitGo mdxType="InstallPureKitGo" />
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      