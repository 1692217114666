import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to delete their account, use the following function`}</span>{`
eThree.resetPrivateKeyBackupWithKeyName(keyName).addCallback(resetListener);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to delete their account, specify keyname`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// use the following function`}</span>{`
eThree.resetPrivateKeyBackupWithKeyName(keyName).addCallback(resetListener)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to delete their account, use the following function to delete their private key`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.resetPrivateKeyBackupWithKeyName(keyName);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
eThree.resetPrivateKeyBackupWithKeyName(keyName)
    .then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}>{`() =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.log(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'success'`}</span>{`))
    .catch(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`e`}</span>{` =>`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`console`}</span>{`.error(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'error: '`}</span>{`, e));`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      