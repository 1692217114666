import { FuseProps } from './Search';

function ellipsisExcerpt(str: string, searchKey: string): string {
	let pos = str.indexOf(searchKey) - 40;
	pos = pos < 0 ? 0 : pos;
	str = `${pos ? '...' : ''}${str.substring(pos, pos + 100)}${str.length > pos + 100 ? '...' : ''}`;
	return str;
}

export function getExcerpt(match: FuseProps, searchKey: string): string {
	let res = match.item.excerpt;
	if (match.matches.length) {
		for (let i = 0; i < match.matches.length; i++) {
			if (match.matches[i].value.includes(searchKey)) {
				res = match.matches[i].value;
			}
		}
	}
	if (res.length > 35) {
		res = ellipsisExcerpt(res, searchKey);
	}
	return res;
}

export function debounce(func: Function, wait: number, immediate?: boolean) {
	let timeout: number | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (...args: any[]) => {
		const later = () => {
			timeout = undefined;
			if (!immediate) func.call(null, ...args);
		};
		const callNow = immediate && !timeout;
		timeout && clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.call(null, ...args);
	};
}
