import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Fetch Virgil JWT token from Back4App function`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` requestParams = mutableMapOf<String, String>().apply {
  put(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"sessionToken"`}</span>{`, sessionToken)
}

ParseCloud.callFunctionInBackground<Map<String, Any>>(
    KEY_VIRGIL_JWT,
    requestParams
) { virgilJwt, exception ->
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (exception == `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`null`}</span>{`)
        emitter.onSuccess(virgilJwt[KEY_TOKEN].toString())
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`else`}</span>{`
        emitter.onError(exception)
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Initialize EThree SDK with JWT token from Back4App Function`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` params = EThreeParams(identity, {preferences.virgilToken()!!}, context)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` ethree = EThree(params)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      