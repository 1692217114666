import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import styles from './FadeAnimation.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FadeAnimation: React.FC<any> = props => {
	return (
		<CSSTransitionGroup
			transitionName={{
				enter: styles.Enter,
				enterActive: styles.EnterActive,
				leave: styles.Leave,
				leaveActive: styles.LeaveActive,
			}}
			transitionEnterTimeout={300}
			transitionLeaveTimeout={300}
			{...props}
		/>
	);
};

export default FadeAnimation;
