import React from 'react';
import classnames from 'classnames';
import { ReactComponent as IconArrowUp } from 'icons/arrow-up.svg';
import styles from './ArrowIcon.module.css';

export enum ArrowDirection {
	Up = 'Up',
	Down = 'Down',
	Left = 'Left',
	Right = 'Right',
}

interface ArrowIconProps extends React.SVGAttributes<SVGElement> {
	direction: ArrowDirection;
	className?: string;
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ direction, className, ...props }) => {
	const iconStyle = classnames(
		{
			[styles.Up]: direction === ArrowDirection.Up,
			[styles.Down]: direction === ArrowDirection.Down,
			[styles.Left]: direction === ArrowDirection.Left,
			[styles.Right]: direction === ArrowDirection.Right,
		},
		className,
	);
	return <IconArrowUp {...props} className={iconStyle} />;
};
