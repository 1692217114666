import React, { useContext, useState } from 'react';
import styles from './ContentsMenu.module.css';
import ScrollSpy from '../ScrollSpy/ScrollSpy';
import { TableOfContentContext } from 'src/templates/GuideTemplate';
import { ReactComponent as ContentIcon } from 'icons/content-icon.svg';
import { ReactComponent as IconArrowUp } from 'icons/arrow-up.svg';
import { TableOfContentsItem } from 'src/types/GuideProps';
import { useMedia } from 'src/hooks/useMedia';
import { MOBILE_LAYOUT_MAX_WIDTH_QUERY } from 'src/layoutParams';

interface Headings {
	title: string;
	url: string;
	depth: number;
}

export interface ContentsMenuContentProps {
	headings: Headings[];
}

const transformTableOfContent = (items: TableOfContentsItem[], depth: number): Headings[] => {
	const result: Headings[] = [];
	for (let i = 0; i < items.length; i++) {
		if (items[i].title) {
			result.push({
				title: items[i].title,
				url: items[i].url,
				depth: depth,
			});
		}
		if (items[i].items) {
			result.push(...transformTableOfContent(items[i].items!, depth + 1));
		}
	}
	return result;
};

const ContentsMenuContent: React.FC<ContentsMenuContentProps> = ({ headings }) => {
	const itemsIds = headings.map(el => el.url);

	return (
		<div className={styles.Container}>
			<div className={styles.Title}>
				<ContentIcon className={styles.Icon} />
				Content
			</div>
			<ScrollSpy
				itemsId={itemsIds}
				className={styles.List}
				classNameItem={styles.Item}
				activeClass={styles.Active}
			>
				{headings.map((el, i) => {
					return (
						<a
							key={i}
							className={styles.Link}
							href={el.url}
							style={{ marginLeft: `${el.depth * 15}px` }}
						>
							{el.title}
						</a>
					);
				})}
			</ScrollSpy>
		</div>
	);
};

const ContentsMenuMobileContent: React.FC<ContentsMenuContentProps> = ({ headings }) => {
	const [isHideMenu, setHideMenu] = useState(window.localStorage.getItem('isHideMenu') === 'true');

	const showContentHandler = () => {
		window.localStorage.setItem('isHideMenu', JSON.stringify(!isHideMenu));
		setHideMenu(!isHideMenu);
	};

	if (isHideMenu) {
		return (
			<button onClick={showContentHandler} className={styles.ShowContentBtn}>
				<ContentIcon className={styles.IconBtn} />
				Show content
			</button>
		);
	}

	return (
		<>
			<ContentsMenuContent headings={headings} />
			<button onClick={showContentHandler} className={styles.ShowContentBtn}>
				Hide content
				<IconArrowUp className={styles.IconBtn} />
			</button>
		</>
	);
};

const ContentsMenu: React.FC = () => {
	const { tableOfContents } = useContext(TableOfContentContext);
	const isBigScreen = useMedia(MOBILE_LAYOUT_MAX_WIDTH_QUERY);

	if (tableOfContents.items) {
		let headings = transformTableOfContent(tableOfContents.items, -1);
		headings = headings.filter(el => el.depth === 0 || el.depth === 1);
		if (headings.length <= 1) return null;
		return isBigScreen ? (
			<ContentsMenuContent headings={headings} />
		) : (
			<ContentsMenuMobileContent headings={headings} />
		);
	}
	return null;
};

export default ContentsMenu;
