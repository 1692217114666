import React, { useContext } from 'react';
import ContentsMenu from 'components/ContentsMenu/ContentsMenu';
import Footer from 'src/components/Footer/Footer';

import styles from './PageLayout.module.css';
import PageLayoutContext from './PageLayoutContext';
import classnames from 'classnames';

const DocsLayout = ({ children }) => {
	const { isDocsOpen } = useContext(PageLayoutContext);

	return (
		<React.Fragment>
			<aside className={styles.ContentsMenu}>
				<ContentsMenu />
			</aside>
			<div className={classnames(styles.PageContent, { [styles.disable]: isDocsOpen })}>
				<main>{children}</main>
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default DocsLayout;
