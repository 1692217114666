import React, { useContext } from 'react';
import sideMenu, { MenuItem } from 'content/SideMenu.yml';
import CardLink from '../Navigation/CardLink';
import styles from './SectionNestedPages.module.css';
import { useCurrentLink } from '../DocsMenu/utils';
import PageLayoutContext from 'src/layout/PageLayoutContext';

function findMenuItem<T extends MenuItem>(recursiveArray: T[], findFunction: (obj: T) => boolean) {
	const result = recursiveArray.find(findFunction);
	if (result) return result;

	let i = 0;
	const ARR_LENGTH = recursiveArray.length;

	while (i < ARR_LENGTH) {
		const children = recursiveArray[i].children;
		if (children) {
			const result = findMenuItem(children, findFunction);
			if (result) return result;
		}
		i++;
	}

	return undefined;
}

const SectionNestedPages: React.FC = () => {
	const { path } = useContext(PageLayoutContext);
	const currentLink = useCurrentLink(path);
	const menuItem = findMenuItem(sideMenu, i => {
		if (!i.link) return false;
		return i.link === currentLink;
	});

	if (!menuItem || !menuItem.children) return null;

	return (
		<ul className={styles.list}>
			{menuItem.children
				.filter(i => !i.hiddenInSectionNestedPages)
				.map(child => (
					<li className={styles.item} key={child.link}>
						<CardLink title={child.title} href={child.link} />
					</li>
				))}
		</ul>
	);
};

export default SectionNestedPages;
