import { globalHistory } from '@reach/router';
import { canUseDOM } from 'src/utils/domHelpers';

export function useCurrentLink(path: string) {
	if (canUseDOM()) {
		return globalHistory.location.pathname;
	} else {
		return path;
	}
}

export class MapWithDefault<K, V> extends Map<K, V> {
	constructor(private defaultValue: V, entries?: readonly (readonly [K, V])[] | null) {
		super(entries);
	}

	get(key: K): V {
		if (!this.has(key)) return this.defaultValue;
		return super.get(key)!;
	}
}
