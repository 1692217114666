/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';

interface OverflowMenuTriggerProps {
	ref: React.Ref<any>;
	onClick: (event: React.MouseEvent) => void;
	isMenuOpen: boolean;
}

interface OverflowMenuChildrenProps {
	placement: string;
	ref: React.Ref<any>;
	style: React.CSSProperties;
	isMenuOpen: boolean;
}

interface OverflowMenuProps {
	trigger: (triggerProps: OverflowMenuTriggerProps) => React.ReactNode;
	children: (childrenProps: OverflowMenuChildrenProps) => React.ReactNode;
}

interface OverflowMenuState {
	isOpen: boolean;
}

export class OverflowMenu extends React.Component<OverflowMenuProps, OverflowMenuState> {
	state: OverflowMenuState = {
		isOpen: false,
	};

	componentDidMount() {
		document.addEventListener('click', this.onClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onClick);
	}

	onClick = (event: MouseEvent) => {
		// eslint-disable-next-line react/no-find-dom-node
		const thisNode = ReactDOM.findDOMNode(this);
		let targetNode = event.target as Node | null;

		while (targetNode !== null) {
			if (targetNode === thisNode) return;
			targetNode = targetNode.parentNode;
		}

		this.setState({ isOpen: false });
	};

	render() {
		return (
			<Manager>
				<Reference>
					{({ ref }) =>
						this.props.trigger({ ref, onClick: this.toggle, isMenuOpen: this.state.isOpen })
					}
				</Reference>
				<Popper positionFixed={true}>
					{({ placement, ref, style }) =>
						this.state.isOpen &&
						this.props.children({
							placement,
							ref,
							style,
							isMenuOpen: this.state.isOpen,
						})
					}
				</Popper>
			</Manager>
		);
	}

	toggle = () => this.setState(state => ({ isOpen: !state.isOpen }));
}
