import InitializeE3KitIos from "common/e3kit/initialize/platforms/ios";
import InitializeE3KitAndroid from "common/e3kit/initialize/platforms/android";
import InitializeE3KitJS from "common/e3kit/initialize/platforms/js";
import InitializeE3KitFlutter from "common/e3kit/initialize/platforms/flutter";
import * as React from 'react';
export default {
  InitializeE3KitIos,
  InitializeE3KitAndroid,
  InitializeE3KitJS,
  InitializeE3KitFlutter,
  React
};