import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`String encryptedText = channel.encrypt(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Bob!"`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// send encryptedText`}</span>{`

Data encryptedData = channel.encrypt(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` Data(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Bob!"`}</span>{`.getBytes()));
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// send encryptedData`}</span></code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// `}<span parentName="span" {...{
            "className": "hljs-doctag"
          }}>{`TODO:`}</span>{` Load data to memory`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt data `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedData = channel.encrypt(`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`data`}</span>{` = Data(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Bob!"`}</span>{`.toByteArray()))

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt text`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` encryptedText = channel.encrypt(text = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Bob!"`}</span>{`)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// `}<span parentName="span" {...{
            "className": "hljs-doctag"
          }}>{`TODO:`}</span>{` Load data to memory`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt data `}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedData = channel.encrypt(data: data)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt text`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` encryptedText = channel.encrypt(text: `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Hello, Bob!"`}</span>{`)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      