import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-powershell",
        "metastring": "tabname=Android",
        "tabname": "Android"
      }}>{`// to integrate E3Kit SDK into your Android project `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`using`}</span>{` Gradle,
// add jcenter() repository `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` missing:

repositories {
    jcenter()
}

// `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`set`}</span>{` up dependencies `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{` your \`build.gradle\`:

dependencies {
    // This works `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`for`}</span>{` both Java and Kotlin.
    implementation `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'com.virgilsecurity:ethree:<latest-version>'`}</span>{`

    // `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`If`}</span>{` you want to use kotlin coroutines - use insead:
    // implementation `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'com.virgilsecurity:ethree-coroutines:<latest-version>'`}</span>{`
    // Check out coroutines sample via link: https://github.com/VirgilSecurity/virgil`}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`-e3kit`}</span><span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`-kotlin`}</span>{`/tree/master/samples/android`}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`-kotlin`}</span><span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`-coroutines`}</span>{`

    // You can find <la`}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`test-version`}</span>{`> here: https://github.com/VirgilSecurity/virgil`}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`-e3kit`}</span><span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`-kotlin`}</span>{`
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-shell",
        "metastring": "tabname=JS Browser",
        "tabname": "JS Browser"
      }}>{`npm install -S @virgilsecurity/e3kit-browser`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-shell",
        "metastring": "tabname=React Native",
        "tabname": "React Native"
      }}>{`npm install -S @virgilsecurity/e3kit-native`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash",
        "metastring": "tabname=iOS source=https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/Podfile",
        "tabname": "iOS",
        "source": "https://github.com/VirgilSecurity/demo-e3kit-ios/blob/master/Podfile"
      }}>{`// CocoaPods is a dependency manager `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`for`}</span>{` Cocoa projects.
// You can install it with the following `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`command`}</span>{`:

$ gem install cocoapods

// to integrate Virgil E3Kit into your Xcode project using CocoaPods, specify it `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`in`}</span>{` your Podfile:

target `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'<Your Target Name>'`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`do`}</span>{`
use_frameworks!

pod `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'VirgilE3Kit'`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'~> <latest-version>'`}</span>{`
end

// You can find <latest-version> here: https://github.com/VirgilSecurity/virgil-e3kit-x
// `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`then`}</span>{`, run the following `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`command`}</span>{`:

$ pod install`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      