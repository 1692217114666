import React, { useContext } from 'react';
import { FadeAnimation } from 'src/lib/FadeAnimation';
import MainPanel from '../MainPanel/MainPanel';
import ToggleButton from '../Header/ToggleButton';
import { ReactComponent as IconBook } from 'icons/Icon-Book.svg';
import styles from './Nav.module.css';
import PageLayoutContext from 'src/layout/PageLayoutContext';
import { Link } from 'gatsby';

const Nav: React.FC = () => {
	const { isDocsOpen, setIsDocsOpen } = useContext(PageLayoutContext);

	return (
		<FadeAnimation
			component="nav"
			transitionEnterTimeout={100}
			transitionLeave={false}
			className={styles.Nav}
		>
			{!isDocsOpen && (
				<Link to={'/'} className={styles.MobileDocsNavGroup}>
					<IconBook className={styles.Icon} />
					Documentation
				</Link>
			)}
			<MainPanel className={isDocsOpen && styles.Show} />
			<ToggleButton
				isChecked={isDocsOpen}
				onClick={() => setIsDocsOpen(!isDocsOpen)}
				className={styles.Toggle}
			/>
		</FadeAnimation>
	);
};

export default Nav;
