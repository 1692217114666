import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 {...{
      "id": "install-e3kit"
    }}>{`Install E3Kit`}</h4>
    <p>{`Install `}<inlineCode parentName="p">{`@virgilsecurity/e3kit-native`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-sh"
      }}>{`npm install @virgilsecurity/e3kit-native`}</code></pre>
    <p>{`It is also required to install several mandatory dependencies that aren't bundled in the package:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/VirgilSecurity/react-native-virgil-crypto"
        }}>{`react-native-virgil-crypto`}</a>{` - Virgil Crypto Library for React Native.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/react-native-community/async-storage"
        }}>{`@react-native-community/async-storage`}</a>{` - storage backend for group chats.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/VirgilSecurity/virgil-key-storage-rn"
        }}>{`@virgilsecurity/key-storage-rn`}</a>{` - storage for Virgil Crypto's Private Keys.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/oblador/react-native-keychain"
        }}>{`react-native-keychain`}</a>{` - storage backend for `}<a parentName="li" {...{
          "href": "https://github.com/VirgilSecurity/virgil-key-storage-rn"
        }}>{`@virgilsecurity/key-storage-rn`}</a>{`.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Tip`}</strong>{`: carefully follow the installation guides of each library to avoid problems in future.`}</p>
    </blockquote>
    <h4 {...{
      "id": "initialize-virgil-crypto"
    }}>{`Initialize Virgil Crypto`}</h4>
    <p>{`We created a `}<a parentName="p" {...{
        "href": "https://github.com/VirgilSecurity/react-native-virgil-crypto"
      }}>{`native module for React Native`}</a>{`.`}</p>
    <p>{`This library's API is compatible with the `}<a parentName="p" {...{
        "href": "https://github.com/VirgilSecurity/virgil-crypto-javascript"
      }}>{`virgil-crypto for JavaScript`}</a>{` and can be used in place of the latter in React Native projects. The main difference is that in JS library a class named `}<inlineCode parentName="p">{`VirgilCrypto`}</inlineCode>{` is exported from the module that you need to create instances of, whereas this library exports an "instance" of that class ready to be used. Also, stream encryption is not available as there is no stream implementation in React Native. We're investigating the options to support file encryption though.`}</p>
    <h4 {...{
      "id": "usage"
    }}>{`Usage`}</h4>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'@virgilsecurity/e3kit-native'`}</span>{`;`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      