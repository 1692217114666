import React, { useState, useEffect } from 'react';

import styles from './PageLayout.module.css';
import DocsNavigation from '../components/DocsMenu/DocsNavigation';
import Nav from 'src/components/Nav/Nav';
import PageLayoutContext from './PageLayoutContext';
import { useMedia } from 'src/hooks/useMedia';
import { MOBILE_LAYOUT_MAX_WIDTH_QUERY } from 'src/layoutParams';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PageLayout: React.FC<any> = ({ location, children }) => {
	const isBigScreen = useMedia(MOBILE_LAYOUT_MAX_WIDTH_QUERY);
	const [isMenuOpen, setMenu] = useState(false);
	useEffect(() => void setMenu(isBigScreen), []);
	const providerValue = { path: location.pathname, isDocsOpen: isMenuOpen, setIsDocsOpen: setMenu };
	return (
		<PageLayoutContext.Provider value={providerValue}>
			<div className={styles.container}>
				{children}
				<DocsNavigation />
				<Nav />
			</div>
		</PageLayoutContext.Provider>
	);
};

export default PageLayout;
