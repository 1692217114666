import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`PureContext context = PureContext.createContext(
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AT.znqRZcOdzybj62Rzer897pX2DZ9KZoYF"`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"NM.Qs0YFBS7Mr3Aaa9qn4OCVcNC1wvPCXfUpZNWFCPUOUI="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"BU.MCowBQYDK2VwAyEAKWSBAZbbekJ5Ns36jRcjEiut3rH4rpaCCVnRxcB+d/E="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SK.2.3JE4SAOXGC95nDOjOhCyTXZqdQmjaqz9mtZhIN9s/2g=.cFGlNRis9FQ77DW2fCpBcx+EI4SHv7guS1sPCnSRmx0=.rKEuUZePvcr6CdOTJkk0kd0kUAq1rSx0x2A9lUegQHo="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"PK.2.BCIeyCW9xDfbuYQb6CIJ7dMqujrjBlIysJs6dXxV+9FU9kxC60fD1dl/P/6TT2wJN8p9E16IPNt5OdJ6Tq3S6L0=.BGjcd5b4wzuGapBQXcUxVXnHWvscYMDbTWk1zxqejSruppcoVWzeYlF0z7GTT3HUdejdrFtbL6sRxD/wOu5jYAs="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`null`}</span>{`);

Pure pure = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` Pure(context);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-php"
      }}>{`$context = PureContext::createVirgilContext(
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AT.znqRZcOdzybj62Rzer897pX2DZ9KZoYF"`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"NM.Qs0YFBS7Mr3Aaa9qn4OCVcNC1wvPCXfUpZNWFCPUOUI="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"BU.MCowBQYDK2VwAyEAKWSBAZbbekJ5Ns36jRcjEiut3rH4rpaCCVnRxcB+d/E="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SK.2.3JE4SAOXGC95nDOjOhCyTXZqdQmjaqz9mtZhIN9s/2g=.cFGlNRis9FQ77DW2fCpBcx+EI4SHv7guS1sPCnSRmx0=.rKEuUZePvcr6CdOTJkk0kd0kUAq1rSx0x2A9lUegQHo="`}</span>{`,
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"PK.2.BCIeyCW9xDfbuYQb6CIJ7dMqujrjBlIysJs6dXxV+9FU9kxC60fD1dl/P/6TT2wJN8p9E16IPNt5OdJ6Tq3S6L0=.BGjcd5b4wzuGapBQXcUxVXnHWvscYMDbTWk1zxqejSruppcoVWzeYlF0z7GTT3HUdejdrFtbL6sRxD/wOu5jYAs="`}</span>{`);

$pure = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` Pure($context);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-go"
      }}>{`context, err := purekit.CreateDefaultCloudContext(
	`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"AT.znqRZcOdzybj62Rzer897pX2DZ9KZoYF"`}</span>{`,
	`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"NM.Qs0YFBS7Mr3Aaa9qn4OCVcNC1wvPCXfUpZNWFCPUOUI="`}</span>{`,
	`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"BU.MCowBQYDK2VwAyEAKWSBAZbbekJ5Ns36jRcjEiut3rH4rpaCCVnRxcB+d/E="`}</span>{`,
	`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"SK.2.ieSin9pO6Bx96+RJw/85fARl4fWdD9DfU/JQF+mbdqE=.hMnMH+oIg8eKZNxGq27LYQbzK4gDlvWvMLJh3jBCkRk=.ug2KqJdUl50kYx+/RKcACLCR4zAujSlK6vQMvDKst+w="`}</span>{`,
	`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"PK.2.BO/YeE7oxlUad1TSpKb3DtudynpwB9miM/+1CsMkmf50qP/QUaYt7pXIxrsyLh38u08p6FFkCWedy/VhA//2MJY=.BPkoDhRO/01QmA8JIVcqxMQKrXfA4agTjBFEm42Lh5NIimcEFWUNWXZwq1Q962rxfkJd7kNZDpm0mlPLeHU+iyI="`}</span>{`,
	`}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{`)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
	`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//TODO process error`}</span>{`
}

err = ctx.SetUpdateToken(updateToken)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
	`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//TODO process error`}</span>{`
}

pure, err := purekit.NewPure(context)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
	`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//TODO process error`}</span>{`
}`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      