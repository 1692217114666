import React from 'react';
import classnames from 'classnames';
import { OverflowMenu } from '../OverflowMenu';
import * as styles from './Tabs.module.css';
import { ArrowIcon, ArrowDirection } from '../ArrowIcon/ArrowIcon';

import TabButton from './TabButton';
import { TabProps, Tab } from './Tab';

interface TabsProps {
	selectedIndex?: React.ReactText;
	onChange: (selection: React.ReactText) => void;
	innerRef?: React.RefObject<HTMLDivElement | undefined>;
	children: React.ReactNode;
	className?: string;
	tabClass?: string;
}

interface TabsState {
	visibleTabButtons: React.ReactElement[];
	overflowTabButtons: React.ReactElement[];
	activeTitle?: React.ReactElement;
	isLaidOut: boolean;
	isMeasured: boolean;
}

export class Tabs extends React.Component<TabsProps, TabsState> {
	containerRef = React.createRef<HTMLDivElement>();
	tabButtonRefs: React.RefObject<HTMLElement>[] = [];
	tabButtons: React.ReactElement[] = [];

	state: TabsState = {
		visibleTabButtons: [],
		overflowTabButtons: [],
		isLaidOut: false,
		isMeasured: false,
	};

	rearrange = () => {
		this.tabButtonRefs = [];
		this.tabButtons = [];
		React.Children.forEach(this.props.children, (child: React.ReactElement<TabProps>) => {
			const ref = React.createRef<HTMLButtonElement>();
			const { index, title } = child.props;
			const className = classnames(styles.tabbutton, {
				[styles.selected]: index === this.props.selectedIndex,
			});
			this.tabButtonRefs.push(ref);
			const handleChange = () => this.props.onChange(child.props.index);

			this.tabButtons.push(
				<TabButton
					key={index}
					index={index}
					title={title}
					ref={ref}
					onMount={child.props.onMount}
					className={className}
					onClick={handleChange}
				/>,
			);
		});
		this.setState({ isMeasured: false, isLaidOut: false });
	};

	handleResize = (() => {
		let rafId: number | null = null;
		return () => {
			if (rafId) window.cancelAnimationFrame(rafId);
			rafId = window.requestAnimationFrame(this.rearrange);
		};
	})();

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		this.rearrange();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps: TabsProps) {
		if (this.props.selectedIndex !== prevProps.selectedIndex) {
			this.rearrange();
			return;
		}

		if (!this.state.isMeasured) {
			this.setState({
				visibleTabButtons: this.tabButtons,
				isMeasured: true,
			});
			return;
		}

		if (!this.state.isLaidOut) {
			const allTabButtons = this.tabButtons;
			let visibleTabButtons: React.ReactElement[] = [];
			const overflowTabButtons: React.ReactElement[] = [];
			const selectedTabButton = this.tabButtons.find(it => it.key === this.props.selectedIndex)!;

			if (!this.containerRef.current) throw new Error('no ref');
			const availableWidth = this.containerRef.current.offsetWidth;
			const totalButtonsWidth = this.tabButtonRefs.reduce(
				(sum, ref) => sum + ref.current!.offsetWidth,
				0,
			);

			if (totalButtonsWidth < availableWidth) {
				visibleTabButtons = allTabButtons;
			} else {
				this.tabButtons.forEach(item => {
					if (item !== selectedTabButton) {
						overflowTabButtons.push(item);
					}
				});
			}

			this.setState({
				overflowTabButtons,
				visibleTabButtons,
				activeTitle: selectedTabButton ? selectedTabButton.props.title : 'MORE',
				isLaidOut: true,
			});
		}
	}

	render() {
		const { visibleTabButtons, overflowTabButtons, activeTitle } = this.state;
		const hasOverflowItems = overflowTabButtons.length > 0;
		return (
			<div
				className={classnames(styles.tabs, this.props.className)}
				ref={this.props.innerRef as React.RefObject<HTMLDivElement>}
			>
				<div ref={this.containerRef} className={styles.tabbar}>
					<div className={styles.tabbarList}>{visibleTabButtons}</div>
					<OverflowMenu
						trigger={({ ref, onClick, isMenuOpen }) =>
							hasOverflowItems && (
								<div
									className={classnames(styles.overflowContainer, { [styles.active]: isMenuOpen })}
								>
									<button ref={ref} onClick={onClick} className={styles.overflowMenuTrigger}>
										<span>{activeTitle && activeTitle}</span>
										<ArrowIcon
											className={styles.arrowIcon}
											direction={isMenuOpen ? ArrowDirection.Up : ArrowDirection.Down}
										/>
									</button>
								</div>
							)
						}
					>
						{({ placement, ref, style }) => (
							<ul
								className={styles.tabbarOverflowMenu}
								ref={ref}
								style={style}
								data-placement={placement}
							>
								{overflowTabButtons}
							</ul>
						)}
					</OverflowMenu>
				</div>
				{React.Children.map(
					this.props.children,
					(child: React.ReactElement<TabProps>, i: number) => {
						let selectedIndex = this.props.selectedIndex;
						if (!selectedIndex && i === 0) selectedIndex = child.props.index;
						if (child.props.index !== selectedIndex) return null;
						return (
							<Tab key={child.props.index} {...child.props} className={this.props.tabClass}>
								{child.props.children}
							</Tab>
						);
					},
				)}
			</div>
		);
	}
}

export const CodeTabs: React.FC<TabsProps> = props => {
	return <Tabs className={styles.CodeTabs} {...props} />;
};
