import React, { useState, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { globalHistory } from '@reach/router';
import { throttle } from '@virgilsecurity/virgil-ui/src/lib/throttle';
export interface ScrollSpyProps {
	className?: string;
	classNameItem?: string;
	children: React.ReactElement[];
	itemsId: string[];
	activeClass: string;
}

const ScrollSpy: React.FC<ScrollSpyProps> = ({
	children,
	itemsId,
	className,
	classNameItem,
	activeClass,
}) => {
	const getItems = () => {
		const itm = itemsId.reduce((res, id) => {
			const el = document.getElementById(id.replace('#', ''));
			if (el) {
				res[id] = el.getBoundingClientRect();
			}
			return res;
		}, {});
		return itm;
	};

	const [items, setItems] = useState({});
	const [activeItem, setActiveItem] = useState('');
	const [currentScrollY, setCurrentScrollY] = useState(0);

	useLayoutEffect(() => {
		let active = '';
		if (items[globalHistory.location.hash]) {
			setActiveItem(globalHistory.location.hash);
			return;
		}
		for (const key in items) {
			if (items[key].y < currentScrollY + items[key].height + 20) {
				active = key;
			}
		}
		setActiveItem(active);
	}, [currentScrollY, globalHistory.location.hash, items]);

	useEffect(() => {
		const handleScroll = throttle(() => {
			setCurrentScrollY(window.scrollY);
		}, 50);
		window.addEventListener('scroll', handleScroll);
		setItems(getItems());
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<ul className={className}>
			{children.map((item, i) => {
				return (
					<li
						key={i}
						className={classNames(classNameItem, { [activeClass]: item.props.href === activeItem })}
					>
						{item}
					</li>
				);
			})}
		</ul>
	);
};

export default ScrollSpy;
