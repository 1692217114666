import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<strong parentName="p">{`Virgil Core SDK Go`}</strong>{` is provided as a package named `}<strong parentName="p">{`virgil`}</strong>{`. The package is distributed via github.`}</p>
    <p>{`The package is available for Go 1.12 or newer.`}</p>
    <p>{`Installing the package:`}</p>
    <pre><code parentName="pre" {...{}}>{`go get -u github.com/VirgilSecurity/virgil-sdk-go/v6/sdk
`}</code></pre>
    <p>{`To install the latest `}<strong parentName="p">{`Virgil Crypto Library Go`}</strong>{` wrapper version run:`}</p>
    <pre><code parentName="pre" {...{}}>{`go get -d -u gopkg.in/virgilsecurity/virgil-crypto-go.v5
`}</code></pre>
    <p>{`and then run:`}</p>
    <pre><code parentName="pre" {...{}}>{`cd $GOPATH/src/gopkg.in/virgilsecurity/virgil-crypto-go.v5/
make
`}</code></pre>
    <p>{`Depending on your choice of crypto implementation you should create crypto instance by calling:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-go"
      }}>{`virgil_crypto_go.NewVirgilCrypto()`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`cryptoimpl.NewVirgilCrypto()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      