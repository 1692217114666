import React from 'react';
import classnames from 'classnames';
import styles from './Alerts.module.css';
import AlertsMdxProvider from '../Markdown/AlertsMdxProvider';
import { ReactComponent as WarningIcon } from 'icons/icon-warning-mark.svg';

interface WarningProps {
	className?: string;
	children: string;
}

const Warning: React.FC<WarningProps> = ({ children, className }) => {
	return (
		<div className={classnames(styles.warningContainer, className)}>
			<div className={styles.icon}>
				<WarningIcon />
			</div>
			<AlertsMdxProvider
				paragraphClass={styles.paragraph}
				titleClass={classnames(styles.strong, styles.warningStrong)}
				inlineCode={styles.inlineCode}
			>
				{children}
			</AlertsMdxProvider>
		</div>
	);
};

export default Warning;
