import React from 'react';
import classnames from 'classnames';
import CopyPathButton from 'components/CopyPathButton/CopyPathButton';

import styles from './MarkdownComponents.module.css';
import ZoomImage from '../ZoomImage/ZoomImage';
import { clearTitle, checkDisableZoom } from './utils';

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
	id: string;
}

export const Heading1: React.FC<HeadingProps> = props => {
	return <h1 {...props} className={classnames(styles.h1, props.className)} />;
};

export const Heading2: React.FC<HeadingProps> = props => {
	return (
		<CopyPathButton idSection={props.id}>
			<h2 {...props} className={classnames(styles.h2, props.className)} />
		</CopyPathButton>
	);
};

export const Heading3: React.FC<HeadingProps> = props => {
	return (
		<CopyPathButton idSection={props.id}>
			<h3 {...props} className={classnames(styles.h3, props.className)} />
		</CopyPathButton>
	);
};

export const Heading4: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = props => {
	return <h4 {...props} className={classnames(styles.h4, props.className)} />;
};

export const Paragraph: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = props => {
	return <p {...props} className={classnames(styles.p, props.className)} />;
};

export const Bold: React.FC<React.HTMLAttributes<HTMLSpanElement>> = props => {
	return <strong {...props} className={classnames(styles.strong, props.className)} />;
};

export const Anchor: React.FC<React.HTMLAttributes<HTMLAnchorElement>> = props => {
	return <a {...props} className={classnames(styles.a, props.className)} />;
};

export const OrderedList: React.FC<React.HTMLAttributes<HTMLOListElement>> = props => {
	return <ol {...props} className={classnames(styles.ol, props.className)} />;
};

export const ListItem: React.FC<React.HTMLAttributes<HTMLLIElement>> = props => {
	return <li {...props} className={classnames(styles.li, props.className)} />;
};

export const InlineCode: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
	return <code {...props} className={classnames(styles.inlineCode, props.className)} />;
};

export const Code: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
	return <code {...props} className={classnames(styles.code, props.className)} />;
};

export const Hr: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
	return <hr {...props} className={classnames(styles.hr, props.className)} />;
};

export interface ImgProps extends React.HTMLAttributes<HTMLImageElement> {
	src: string;
	title?: string;
	disablezoom?: string;
}

type MdxAdditionalProps = {
	style: {
		cssFloat?:
			| '-moz-initial'
			| 'inherit'
			| 'initial'
			| 'revert'
			| 'unset'
			| 'inline-end'
			| 'inline-start'
			| 'left'
			| 'none'
			| 'right'
			| undefined;
	};
};

export const Img: React.FC<ImgProps & MdxAdditionalProps> = props => {
	if (props.style && props.style.cssFloat) props.style.float = props.style.cssFloat;
	return (
		<ZoomImage src={props.src} disablezoom={checkDisableZoom(props.disablezoom, props.title)}>
			<img
				{...props}
				title={clearTitle(props.title)}
				className={classnames(styles.img, props.className)}
			/>
		</ZoomImage>
	);
};

export interface BlockQuoteProps {
	className?: string;
	children: string;
}

export const BlockQuote: React.FC<BlockQuoteProps> = ({ children, className }) => {
	return (
		<div className={classnames(styles.blockQuoteContainer, className)}>
			<div>{children}</div>
		</div>
	);
};
