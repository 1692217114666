import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`Group group = ethree.getGroup(groupId);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` groupId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'unique_group_id'`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` group = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.getGroup(groupId);`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` group = ethree.getGroup(groupId)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` group = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! eThree.getGroup(id: groupId)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      