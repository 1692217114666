import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`Minimum supported version of Node.js is `}<inlineCode parentName="p">{`8.6.0`}</inlineCode>{`.`}</p>
    </blockquote>
    <p>{`Install the following Virgil E3Kit package using npm in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`npm install -S @virgilsecurity/e3kit-node`}</code></pre>
    <h4 {...{
      "id": "usage"
    }}>{`Usage`}</h4>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'@virgilsecurity/e3kit-node'`}</span>{`;`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      