import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The PureKit Kotlin/Java is provided as a package named `}<inlineCode parentName="p">{`purekit`}</inlineCode>{` with group id named com.virgilsecurity.
You can either use Gradle or Maven to add it to your project dependencies.`}</p>
    <p>{`Maven
Add `}<inlineCode parentName="p">{`jcenter`}</inlineCode>{` repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`<repositories>
    <repository>
        <id>jcenter</id>
        <name>jCenter</name>
        <url>http://jcenter.bintray.com</url>
    </repository>
</repositories>`}</code></pre>
    <p>{`and add `}<inlineCode parentName="p">{`purekit`}</inlineCode>{` dependency:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`<dependencies>
    <dependency>
        <groupId>com.virgilsecurity</groupId>
        <artifactId>purekit</artifactId>
        <version><latest-version></version>
    </dependency>
</dependencies>`}</code></pre>
    <p>{`Gradle`}</p>
    <p>{`Add `}<inlineCode parentName="p">{`jcenter`}</inlineCode>{` repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`repositories {
    jcenter()
}`}</code></pre>
    <p>{`and add `}<inlineCode parentName="p">{`purekit`}</inlineCode>{` dependency:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`implementation `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'com.virgilsecurity:purekit:<latest-version>'`}</span></code></pre>
    <p>{`The `}<strong parentName="p">{`<latest-version`}{`>`}</strong>{` of the SDK can be found in the Maven Central Repository:
`}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/com.virgilsecurity/purekit"
      }}>{`https://mvnrepository.com/artifact/com.virgilsecurity/purekit`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      