import React from 'react';
import styles from './CopyPathButton.module.css';
import { ReactComponent as CopyIcon } from 'icons/copy-icon.svg';
import { globalHistory } from '@reach/router';

export interface CopyPathButtonProp {
	children: React.ReactNode;
	idSection: string;
}

const CopyPathButton: React.FC<CopyPathButtonProp> = ({ children, idSection }) => {
	const copyToClipboard = () => {
		const pathArray = globalHistory.location.href.split('#');
		const path = pathArray[0] + `#${idSection}`;
		globalHistory.navigate(path);

		const textarea = document.createElement('textarea');
		textarea.style.top = '0';
		textarea.style.opacity = '0';
		textarea.style.position = 'fixed';
		document.body.appendChild(textarea);
		textarea.value = path;
		textarea.focus();
		textarea.select();
		textarea.setSelectionRange(0, textarea.value.length);
		document.execCommand('copy');
		textarea.remove();
	};

	return (
		<div className={styles.container}>
			<div onClick={copyToClipboard} className={styles.icon}>
				<CopyIcon />
			</div>
			{children}
		</div>
	);
};

export default CopyPathButton;
