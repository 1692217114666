export function checkDisableZoom(
	disablezoom: string | undefined,
	title: string | undefined,
): boolean {
	if (disablezoom && disablezoom === 'true') {
		return true;
	}
	if (title && title.includes('| disablezoom')) {
		return true;
	}
	return false;
}

export function clearTitle(title: string | undefined): string | undefined {
	if (title && title.includes('| disablezoom')) {
		return title.replace('| disablezoom', '');
	}
	return title;
}
