import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript",
        "metastring": "tabname=Node.js",
        "tabname": "Node.js"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// server.js`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` express = `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`require`}</span>{`(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'express'`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` { initCrypto, VirgilCrypto, VirgilAccessTokenSigner } = `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`require`}</span>{`(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'virgil-crypto'`}</span>{`);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` { JwtGenerator } = `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`require`}</span>{`(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'virgil-sdk'`}</span>{`);

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-keyword"
          }}>{`function`}</span>{` `}<span parentName="span" {...{
            "className": "hljs-title"
          }}>{`getJwtGenerator`}</span>{`(`}<span parentName="span" {...{
            "className": "hljs-params"
          }}></span>{`) `}</span>{`{
  `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` initCrypto();

  `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` virgilCrypto = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilCrypto();
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize JWT generator with your App ID and App Key ID you got in`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Virgil Dashboard.`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` JwtGenerator({
    `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`appId`}</span>{`: process.env.APP_ID,
    `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`apiKeyId`}</span>{`: process.env.APP_KEY_ID,
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// import your App Key that you got in Virgil Dashboard from string.`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`apiKey`}</span>{`: virgilCrypto.importPrivateKey(process.env.APP_KEY),
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize accessTokenSigner that signs users JWTs`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`accessTokenSigner`}</span>{`: `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilAccessTokenSigner(virgilCrypto),
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// JWT lifetime - 20 minutes (default)`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`millisecondsToLive`}</span>{`:  `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`20`}</span>{` * `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`60`}</span>{` * `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`1000`}</span>{`
  });
}

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` generatorPromise = getJwtGenerator();

app.get(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'/virgil-jwt'`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-function"
        }}>{`(`}<span parentName="span" {...{
            "className": "hljs-params"
          }}>{`req, res`}</span>{`) =>`}</span>{` {
  `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` generator = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` generatorPromise;
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Get the identity of the user making the request (this assumes the request`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// is authenticated and there is middleware in place that populates the`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// \`req.user\` property with the user record).`}</span>{`
  `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` virgilJwtToken = generator.generateToken(req.user.identity);
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Send it to the authorized user`}</span>{`
  res.json({ `}<span parentName="code" {...{
          "className": "hljs-attr"
        }}>{`virgilToken`}</span>{`: virgilJwtToken.toString() });
});`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key (you got this Key at the Virgil Dashboard)`}</span>{`
String appKeyBase64 = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"MC4CAQAwBQYDK2VwBCIEINlK4BhgsijAbNmUqU6us0ZU9MGi+HxdYCA6TdZeHjR4"`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`byte`}</span>{`[] appKeyData = ConvertionUtils.base64ToBytes(appKeyBase64);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Crypto library imports a key pair`}</span>{`
VirgilCrypto crypto = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilCrypto();
VirgilKeyPair keyPair = crypto.importPrivateKey(appKeyData);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Initialize an access token signer that signs users JWTs`}</span>{`
VirgilAccessTokenSigner accessTokenSigner = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilAccessTokenSigner();

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Use your App Credentials you got at the Virgil Dashboard:`}</span>{`
String appId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"be00e10e4e1f4bf58f9b4dc85d79c77a"`}</span>{`;
String appKeyId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"70b447e321f3a0fd"`}</span>{`;
TimeSpan ttl = TimeSpan.fromTime(`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`1`}</span>{`, TimeUnit.HOURS); `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1 hour - JWT's lifetime`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Setup a JWT generator with the required parameters:`}</span>{`
JwtGenerator jwtGenerator =
	`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` JwtGenerator(appId, keyPair.getPrivateKey(), appKeyId, ttl, accessTokenSigner);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Generate a JWT for a user`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Remember that you must provide each user with a unique JWT.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Each JWT contains unique user's identity (in this case - Alice).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Identity can be any value: name, email, some id etc.`}</span>{`
String identity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`;
Jwt aliceJwt = jwtGenerator.generateToken(identity);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// As a result you get user's JWT, it looks like this: "eyJraWQiOiI3MGI0NDdlMzIxZjNhMGZkIiwidHlwIjoiSldUIiwiYWxnIjoiVkVEUzUxMiIsImN0eSI6InZpcmdpbC1qd3Q7dj0xIn0.eyJleHAiOjE1MTg2OTg5MTcsImlzcyI6InZpcmdpbC1iZTAwZTEwZTRlMWY0YmY1OGY5YjRkYzg1ZDc5Yzc3YSIsInN1YiI6ImlkZW50aXR5LUFsaWNlIiwiaWF0IjoxNTE4NjEyNTE3fQ.MFEwDQYJYIZIAWUDBAIDBQAEQP4Yo3yjmt8WWJ5mqs3Yrqc_VzG6nBtrW2KIjP-kxiIJL_7Wv0pqty7PDbDoGhkX8CJa6UOdyn3rBWRvMK7p7Ak".`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// You can provide users with JWT at registration or authorization steps.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Send a JWT to client-side.`}</span>{`
String jwtString = aliceJwt.stringRepresentation();`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-go"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//Import the required libraries`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` (
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"encoding/base64"`}</span>{`

    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"github.com/VirgilSecurity/virgil-sdk-go/v6/crypto"`}</span>{`
    `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"github.com/VirgilSecurity/virgil-sdk-go/v6/session"`}</span>{`
)

...

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key (you got this Key at Virgil Dashboard)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Decode it from base64 format`}</span>{`
privateKeyData, err := base64.RawURLEncoding.DecodeString([]`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`byte`}</span>{`{`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"MIGhMF0GCSqGSIb3DQEFDTBQMC8GCSqGSIb3DQEFDDAiBBC7Sg/DbNzhJ/uakTva"`}</span>{`})
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//handle error`}</span>{`
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Crypto library imports a private key into the required format`}</span>{`
vcrypto := &crypto.Crypto{}
privateKey, err := vcrypto.ImportPrivateKey(privateKeyData)
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//handle error`}</span>{`
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// use your App Credentials you got at Virgil Dashboard:`}</span>{`
appID := `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"be00e10e4e1f4bf58f9b4dc85d79c77a"`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App ID`}</span>{`
appKeyID := `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"70b447e321f3a0fd"`}</span>{`               `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key ID`}</span>{`
ttl := time.Hour                            `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1 hour (JWT's lifetime)`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// setup JWT generator with the required parameters:`}</span>{`
jwtGenerator = session.JwtGenerator{
    AppKey:   privateKey,
    AppID:    appID,
    AppKeyID: appKeyID,
    TTL: ttl,
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// generate JWT for a user`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// remember that you must provide each user with a unique JWT`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// each JWT contains unique user's identity (in this case - Alice)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// identity can be any value: name, email, some id etc.`}</span>{`
identity := `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`
token, err := jwtGenerator.GenerateToken(identity, `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{`)

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` err != `}<span parentName="code" {...{
          "className": "hljs-literal"
        }}>{`nil`}</span>{` {
  `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//handle error`}</span>{`
}

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// as a result you get users JWT, it looks like this: "eyJraWQiOiI3MGI0NDdlMzIxZjNhMGZkIiwidHlwIjoiSldUIiwiYWxnIjoiVkVEUzUxMiIsImN0eSI6InZpcmdpbC1qd3Q7dj0xIn0.eyJleHAiOjE1MTg2OTg5MTcsImlzcyI6InZpcmdpbC1iZTAwZTEwZTRlMWY0YmY1OGY5YjRkYzg1ZDc5Yzc3YSIsInN1YiI6ImlkZW50aXR5LUFsaWNlIiwiaWF0IjoxNTE4NjEyNTE3fQ.MFEwDQYJYIZIAWUDBAIDBQAEQP4Yo3yjmt8WWJ5mqs3Yrqc_VzG6nBtrW2KIjP-kxiIJL_7Wv0pqty7PDbDoGhkX8CJa6UOdyn3rBWRvMK7p7Ak"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// you can provide users with JWT at registration or authorization steps`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Send a JWT to client-side`}</span>{`
jwtString := token.String()`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-cs"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`using`}</span>{` Virgil.Crypto;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`using`}</span>{` Virgil.SDK;

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key (you got this Key at Virgil Dashboard)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` appKeyBase64 = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"MIGhMF0GCSqGSIb3DQEFDTBQMC8GCSqGSIb3DQEFDDAiBBC7Sg/DbNzhJ/uakTvafUMoAgIUtzAKBggqhkiG9w0CCjAdBglghkgBZQMEASoEEDunQ1yhWZoKaLaDFgjpxRwEQAFdbC8e6103lJrUhY9ahyUA8+4rTJKZCmdTlCDPvoWH/5N5kxbOvTtbxtxevI421z3gRbjAtoWkfWraSLD6gj0="`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` privateKeyData = Bytes.FromString(appKeyBase64, StringEncoding.BASE64);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Crypto library imports a private key into a necessary format`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` crypto = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilCrypto();
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` appKey = crypto.ImportPrivateKey(privateKeyData, appKeyPassword);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`//  initialize accessTokenSigner that signs users JWTs`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` accessTokenSigner = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilAccessTokenSigner();

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// use your App Credentials you got at Virgil Dashboard:`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` appId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"be00e10e4e1f4bf58f9b4dc85d79c77a"`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App ID`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` appKeyId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"70b447e321f3a0fd"`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key ID`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` ttl = TimeSpan.FromHours(`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`1`}</span>{`); `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1 hour (JWT's lifetime)`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// setup JWT generator with the required parameters:`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` jwtGenerator = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` JwtGenerator(appId, appKey, appKeyId, ttl, accessTokenSigner);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// generate JWT for a user`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// remember that you must provide each user with a unique JWT`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// each JWT contains unique user's identity (in this case - Alice)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// identity can be any value: name, email, some id etc.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` identity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` aliceJwt = jwtGenerator.GenerateToken(identity);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// as a result you get users JWT, it looks like this: "eyJraWQiOiI3MGI0NDdlMzIxZjNhMGZkIiwidHlwIjoiSldUIiwiYWxnIjoiVkVEUzUxMiIsImN0eSI6InZpcmdpbC1qd3Q7dj0xIn0.eyJleHAiOjE1MTg2OTg5MTcsImlzcyI6InZpcmdpbC1iZTAwZTEwZTRlMWY0YmY1OGY5YjRkYzg1ZDc5Yzc3YSIsInN1YiI6ImlkZW50aXR5LUFsaWNlIiwiaWF0IjoxNTE4NjEyNTE3fQ.MFEwDQYJYIZIAWUDBAIDBQAEQP4Yo3yjmt8WWJ5mqs3Yrqc_VzG6nBtrW2KIjP-kxiIJL_7Wv0pqty7PDbDoGhkX8CJa6UOdyn3rBWRvMK7p7Ak"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// you can provide users with JWT at registration or authorization steps`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Send a JWT to client-side`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`var`}</span>{` jwtString = aliceJwt.ToString();`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-php"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`use`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`Virgil`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`CryptoImpl`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`VirgilAccessTokenSigner`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`use`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`Virgil`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`CryptoImpl`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`VirgilCrypto`}</span>{`;

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`use`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`Virgil`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`Sdk`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`Web`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`Authorization`}</span>{`\\`}<span parentName="code" {...{
          "className": "hljs-title"
        }}>{`JwtGenerator`}</span>{`;

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key (you got this Key at Virgil Dashboard)`}</span>{`
$privateKeyStr = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"MIGhMF0GCSqGSIb3DQEFDTBQMC8GCSqGSIb3DQEFDDAiBBC7Sg/DbNzhJ/uakTva"`}</span>{`;
$appKeyData = base64_decode($privateKeyStr);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Crypto library imports a private key into a necessary format`}</span>{`
$crypto = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilCrypto();
$privateKey = $crypto->importPrivateKey($appKeyData);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// initialize accessTokenSigner that signs users JWTs`}</span>{`
$accessTokenSigner = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` VirgilAccessTokenSigner();

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// use your App Credentials you got at Virgil Dashboard:`}</span>{`
$appId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"be00e10e4e1f4bf58f9b4dc85d79c77a"`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App ID`}</span>{`
$appKeyId = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"70b447e321f3a0fd"`}</span>{`;              `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// App Key ID`}</span>{`
$ttl = `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`3600`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// 1 hour (JWT's lifetime)`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// setup JWT generator with the required parameters:`}</span>{`
$jwtGenerator = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`new`}</span>{` JwtGenerator($privateKey, $appKeyId, $accessTokenSigner, $appId, $ttl);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// generate JWT for a user`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// remember that you must provide each user with a unique JWT`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// each JWT contains unique user's identity (in this case - Alice)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// identity can be any value: name, email, some id etc.`}</span>{`
$identity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`;
$token = $jwtGenerator->generateToken($identity);

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// as a result you get users JWT, it looks like this: "eyJraWQiOiI3MGI0NDdlMzIxZjNhMGZkIiwidHlwIjoiSldUIiwiYWxnIjoiVkVEUzUxMiIsImN0eSI6InZpcmdpbC1qd3Q7dj0xIn0.eyJleHAiOjE1MTg2OTg5MTcsImlzcyI6InZpcmdpbC1iZTAwZTEwZTRlMWY0YmY1OGY5YjRkYzg1ZDc5Yzc3YSIsInN1YiI6ImlkZW50aXR5LUFsaWNlIiwiaWF0IjoxNTE4NjEyNTE3fQ.MFEwDQYJYIZIAWUDBAIDBQAEQP4Yo3yjmt8WWJ5mqs3Yrqc_VzG6nBtrW2KIjP-kxiIJL_7Wv0pqty7PDbDoGhkX8CJa6UOdyn3rBWRvMK7p7Ak"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// you can provide users with JWT at registration or authorization steps`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Send a JWT to client-side`}</span>{`
$token->__toString();`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-python"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` datetime

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` virgil_crypto `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` VirgilCrypto
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` virgil_crypto.access_token_signer `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` AccessTokenSigner
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` virgil_sdk.jwt `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` JwtGenerator
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`from`}</span>{` virgil_sdk.utils `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`import`}</span>{` Utils

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# App Key (you got this Key at Virgil Dashboard)`}</span>{`
app_key_base64 = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"MIGhMF0GCSqGSIb3DQEFDTBQMC8GCSqGS...gRbjAtoWkfWraSLD6gj0="`}</span>{`
private_key_data = Utils.b64_decode(app_key_base64)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# Crypto library imports a private key into a necessary format`}</span>{`
crypto = VirgilCrypto()
app_key = crypto.import_private_key(private_key_data)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`#  initialize accessTokenSigner that signs users JWTs`}</span>{`
access_token_signer = AccessTokenSigner()

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# use your App Credentials you got at Virgil Dashboard:`}</span>{`
app_id = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"be00e10e4e1f4bf58f9b4dc85d79c77a"`}</span>{`
app_key_id = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"70b447e321f3a0fd"`}</span>{`
ttl = datetime.timedelta(hours=`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`1`}</span>{`).seconds

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# setup JWT generator with the required parameters:`}</span>{`
jwt_generator = JwtGenerator(app_id, app_key, app_key_id, ttl, access_token_signer)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# generate JWT for a user`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# remember that you must provide each user with a unique JWT`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# each JWT contains unique user's identity (in this case - Alice)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# identity can be any value: name, email, some id etc.`}</span>{`
identity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`
alice_jwt = jwt_generator.generate_token(identity)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# as a result you get users JWT, it looks like this: "eyJraWQiOiI3MGI0NDdlMzIxZjNhMGZkIiwidHlwIjoiSldUIiwiYWxnIjoiVkVEUzUxMiIsImN0eSI6InZpcmdpbC1qd3Q7dj0xIn0.eyJleHAiOjE1MTg2OTg5MTcsImlzcyI6InZpcmdpbC1iZTAwZTEwZTRlMWY0YmY1OGY5YjRkYzg1ZDc5Yzc3YSIsInN1YiI6ImlkZW50aXR5LUFsaWNlIiwiaWF0IjoxNTE4NjEyNTE3fQ.MFEwDQYJYIZIAWUDBAIDBQAEQP4Yo3yjmt8WWJ5mqs3Yrqc_VzG6nBtrW2KIjP-kxiIJL_7Wv0pqty7PDbDoGhkX8CJa6UOdyn3rBWRvMK7p7Ak"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# you can provide users with JWT at registration or authorization steps`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# Send a JWT to client-side`}</span>{`
jwt_string = alice_jwt.to_string()`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-ruby"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`require`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'virgil/crypto'`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`require`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'virgil/jwt'`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`include`}</span>{` Virgil::Crypto
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`include`}</span>{` Virgil::Jwt

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# API_KEY (you got this Key at Virgil Dashboard)`}</span>{`
api_key_base64 = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"MC4CAQAwBQYDK2VwBCIEID8cTZz/sz2/iQ7mOndqwVpVazM8cUmjF49pPBqlqX3l"`}</span>{`
private_key_data = Bytes.from_string(api_key_base64, VirgilStringEncoding::BASE64)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# Crypto library imports a private key into a required format`}</span>{`
crypto = VirgilCrypto.new
api_key = crypto.import_private_key(private_key_data, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`""`}</span>{`)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`#  initialize accessTokenSigner that signs users JWTs`}</span>{`
access_token_signer = VirgilAccessTokenSigner.new

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# use your App Credentials you got at Virgil Dashboard:`}</span>{`
app_id = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"be00e10e4e1f4bf58f9b4dc85d79c77a"`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# APP_ID`}</span>{`
api_key_id = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"70b447e321f3a0fd"`}</span>{`; `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# API_KEY_ID`}</span>{`
ttl = `}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`1`}</span>{`*`}<span parentName="code" {...{
          "className": "hljs-number"
        }}>{`60`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# 1 hour (JWT's lifetime in minutes)`}</span>{`

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# setup JWT generator with the required parameters:`}</span>{`
jwt_generator = JwtGenerator.new(`}<span parentName="code" {...{
          "className": "hljs-symbol"
        }}>{`app_id:`}</span>{` app_id, 
                                 `}<span parentName="code" {...{
          "className": "hljs-symbol"
        }}>{`api_key:`}</span>{` api_key, 
                                 `}<span parentName="code" {...{
          "className": "hljs-symbol"
        }}>{`api_public_key_id:`}</span>{` api_key_id, 
                                 `}<span parentName="code" {...{
          "className": "hljs-symbol"
        }}>{`life_time:`}</span>{` ttl, 
                                 `}<span parentName="code" {...{
          "className": "hljs-symbol"
        }}>{`access_token_signer:`}</span>{` access_token_signer)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# generate JWT for a user`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# remember that you must provide each user with a unique JWT`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# each JWT contains unique user's identity (in this case - Alice)`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# identity can be any value: name, email, some id etc.`}</span>{`
identity = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`
alice_jwt = jwt_generator.generate_token(identity)

`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# as a result you get users JWT, it looks like this: "eyJraWQiOiI3MGI0NDdlMzIxZjNhMGZkIiwidHlwIjoiSldUIiwiYWxnIjoiVkVEUzUxMiIsImN0eSI6InZpcmdpbC1qd3Q7dj0xIn0.eyJleHAiOjE1MTg2OTg5MTcsImlzcyI6InZpcmdpbC1iZTAwZTEwZTRlMWY0YmY1OGY5YjRkYzg1ZDc5Yzc3YSIsInN1YiI6ImlkZW50aXR5LUFsaWNlIiwiaWF0IjoxNTE4NjEyNTE3fQ.MFEwDQYJYIZIAWUDBAIDBQAEQP4Yo3yjmt8WWJ5mqs3Yrqc_VzG6nBtrW2KIjP-kxiIJL_7Wv0pqty7PDbDoGhkX8CJa6UOdyn3rBWRvMK7p7Ak"`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# you can provide users with JWT at registration or authorization steps`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`# Send a JWT to client-side`}</span>{`
jwt_string = alice_jwt.to_s
puts(jwt_string)`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      