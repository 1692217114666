/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import Fuse, { FuseOptions } from 'fuse.js';
import { GuideProps } from 'src/types/GuideProps.js';
import styles from './Search.module.css';
import { ReactComponent as SearchIcon } from 'icons/Icon-L-Search.svg';
import classnames from 'classnames';
import SearchContext from './SearchContext';
import ResultsList from './ResultsList';
import { getExcerpt, debounce } from './utils';
import { ReactComponent as CloseIcon } from 'icons/Icon-L-Close.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SEARCH_ENGINE_OPTIONS: FuseOptions<MatchesProps> = {
	shouldSort: true,
	threshold: 0.3,
	location: 0,
	distance: 1000000,
	maxPatternLength: 32,
	includeMatches: true,
	keys: [
		{
			name: 'frontmatter.search_keywords',
			weight: 0.3,
		},
		{
			name: 'frontmatter.tab_title',
			weight: 0.25,
		},
		{
			name: 'frontmatter.main_title',
			weight: 0.2,
		},
		{
			name: 'headings.value',
			weight: 0.15,
		},
		{
			name: 'paragraphContent',
			weight: 0.1,
		},
	],
};

let searchEngine: Fuse<FuseProps, any> | undefined;

export interface MatchesProps extends GuideProps {
	matches: string;
	searchKey: string;
}

export interface FuseProps {
	item: GuideProps;
	matches: {
		value: string;
	}[];
}

const Search: React.FC = () => {
	const [searchResults, setSearchResults] = React.useState<null | MatchesProps[]>(null);
	const [matchesRes, setMatchesRes] = React.useState('');
	const { isSearchOpen, setIsSearchOpen } = React.useContext(SearchContext);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const debounceSetMatchesRes = debounce(res => {
		setMatchesRes(res);
	}, 500);
	const debounceSetSearchResults = debounce(res => {
		setSearchResults(res);
	}, 500);

	const loadSearchIndex = () => {
		if (searchEngine) return;
		import('../../searchIndex.json').then(module => {
			searchEngine = new Fuse<any, any>(module.default, SEARCH_ENGINE_OPTIONS);
		});
	};

	const startSearch = (searchKey: string) => {
		if (!searchEngine) return;
		if (searchKey.length === 0) {
			debounceSetMatchesRes('');
			debounceSetSearchResults(null);
			setIsSearchOpen(false);
			return;
		}
		setIsSearchOpen(true);
		const searchRes: any[] = searchEngine.search(searchKey);
		const result = searchRes.map(res => {
			return {
				...res.item,
				searchKey: searchKey,
				matches: getExcerpt(res, searchKey),
			};
		});
		debounceSetSearchResults(result);
		if (result.length) {
			debounceSetMatchesRes(`${result.length} matches found for '${searchKey}'`);
		} else {
			debounceSetMatchesRes(`No matches found for '${searchKey}'`);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		startSearch(e.target.value.trim());
	};

	const clearInput = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
			startSearch('');
		}
	};

	return (
		<div className={classnames(styles.Container, { [styles.Active]: isSearchOpen })}>
			<div className={styles.InputContainer}>
				<input
					onFocus={loadSearchIndex}
					onChange={handleChange}
					placeholder={'Search'}
					className={styles.InputSearch}
					maxLength={32}
					ref={inputRef}
				/>
				<SearchIcon className={styles.SearchIcon} />
				<CloseIcon className={styles.CloseIcon} onClick={clearInput} />
			</div>
			<span className={styles.Matches}>{matchesRes}</span>
			{searchResults && isSearchOpen && <ResultsList searchResults={searchResults} />}
		</div>
	);
};

export default Search;
