import React from 'react';
import styles from './Alerts.module.css';
import classnames from 'classnames';
import AlertsMdxProvider from '../Markdown/AlertsMdxProvider';
import { ReactComponent as InfoIcon } from 'icons/icon-info-mark.svg';

export interface InfoProps {
	children: string;
}

const Info: React.FC<InfoProps> = ({ children }) => {
	return (
		<div className={styles.infoContainer}>
			<div className={styles.icon}>
				<InfoIcon />
			</div>
			<AlertsMdxProvider
				paragraphClass={styles.paragraph}
				titleClass={classnames(styles.strong, styles.infoStrong)}
				inlineCode={styles.inlineCode}
			>
				{children}
			</AlertsMdxProvider>
		</div>
	);
};

export default Info;
