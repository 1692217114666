import React, { useEffect, useContext } from 'react';
import { MenuItem } from '*/SideMenu.yml';
import styles from './DocsMenu.module.css';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { ArrowIcon, ArrowDirection } from '@virgilsecurity/virgil-ui/src/components/ArrowIcon';
import { ReactComponent as OuterLinkIcon } from 'icons/Icon-L-External.svg';
import PageLayoutContext from 'src/layout/PageLayoutContext';
import { useCurrentLink } from './utils';

export interface DocsMenuLinkProps {
	item: MenuItem;
	depth: number;
	isOpen: boolean;
	isActive: boolean;
	setOpen: (b: boolean) => void;
}

const linkClassMap = new Map([
	[0, styles.TopLevelItem],
	[1, styles.SecondLevelItem],
	[2, styles.ThirdLevelItem],
]);

const linkActiveClassMap = new Map([
	[0, styles.TopLevelItemActive],
	[1, styles.SecondLevelItemActive],
	[2, styles.ThirdLevelItemActive],
]);

const openLinkClassMap = new Map([
	[0, styles.TopLevelItemOpen],
	[1, styles.SecondLevelItemOpen],
	[2, styles.ThirdLevelItemOpen],
]);

const DocsMenuLink: React.FC<DocsMenuLinkProps> = ({ item, isOpen, depth, isActive, setOpen }) => {
	let linkClass = !isOpen ? linkClassMap.get(depth) : openLinkClassMap.get(depth);
	const iconClassName = !isOpen ? styles.IconDefault : styles.IconActive;
	const { setIsDocsOpen, path } = useContext(PageLayoutContext);
	const currentLink = useCurrentLink(path);
	const closeMobileMenu = () => {
		setIsDocsOpen(false);
	};

	useEffect(() => {
		if (currentLink === item.link) {
			setOpen(true);
		}
	}, [currentLink]);

	if (isActive) linkClass = classNames(linkClass, linkActiveClassMap.get(depth));

	const getLinkElement = () => {
		if (item.link[0] === '/') {
			return (
				<Link to={item.link} className={linkClass} onClick={closeMobileMenu}>
					{item.title}
				</Link>
			);
		} else {
			return (
				<a
					href={item.link}
					className={classNames(linkClass, styles.outerLink)}
					target="_blank"
					rel="noopener noreferrer"
					onClick={closeMobileMenu}
				>
					{item.title}
					<OuterLinkIcon className={styles.outerIcon} />
				</a>
			);
		}
	};

	return (
		<React.Fragment>
			{getLinkElement()}
			{item.children && (
				<div className={styles.ExpandingArrowContainer}>
					<button
						className={styles.ExpandingArrow}
						onClick={e => {
							// I don't know why, but without this <details /> not closing sometimes...
							e.preventDefault();
							setOpen(!isOpen);
						}}
					>
						<ArrowIcon
							className={iconClassName}
							direction={isOpen ? ArrowDirection.Down : ArrowDirection.Right}
						/>
					</button>
				</div>
			)}
		</React.Fragment>
	);
};

export default DocsMenuLink;
