import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!eThree.hasLocalPrivateKey()) {
    eThree.restorePrivateKey(keyName, keyPassword).addCallback(restoreListener);
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!eThree.hasLocalPrivateKey()) {
    eThree.restorePrivateKey(keyName, keyPassword).addCallback(restoreListener)
}`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-javascript"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// If user wants to restore her private key from backup in Virgil Cloud.`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// While user in session - key can be removed and restore multiply times (via cleanup/restorePrivateKey functions).`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// To know whether private key is present on device now use hasLocalPrivateKey() function:`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` hasLocalPrivateKey = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.hasLocalPrivateKey();
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!hasLocalPrivateKey) `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.restorePrivateKey(keyPassword, keyName);
`}<span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// or`}</span>{`
eThree.hasLocalPrivateKey().then(`}<span parentName="code" {...{
          "className": "hljs-function"
        }}><span parentName="span" {...{
            "className": "hljs-params"
          }}>{`hasLocalPrivateKey`}</span>{` =>`}</span>{` {
    `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`if`}</span>{` (!hasLocalPrivateKey) eThree.restorePrivateKey(keyPassword, keyName);
});`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      