import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import COMMON_COMPONENTS from './commonComponents';
import { Heading1 } from './MarkdownComponents';

import ContentsMenu from '../ContentsMenu/ContentsMenu';
import { HeadingProps } from './MarkdownComponents';
import { useMedia } from 'src/hooks/useMedia';
import { MOBILE_LAYOUT_MAX_WIDTH_QUERY } from 'src/layoutParams';
import HideFromGoogle from '../Seo/HideFromGoogle';

const MainHeader: React.FC<HeadingProps> = props => {
	const isBigScreen = useMedia(MOBILE_LAYOUT_MAX_WIDTH_QUERY);

	const renderMenu = () => {
		if (isBigScreen) return null;
		return (
			<HideFromGoogle>
				<ContentsMenu />
			</HideFromGoogle>
		);
	};

	return (
		<span>
			<Heading1 {...props} />
			{renderMenu()}
		</span>
	);
};

const GUIDE_COMPONENTS = {
	...COMMON_COMPONENTS,
	h1: MainHeader,
};

const GuideMdxProvider: React.FC = ({ children }) => (
	<MDXProvider components={GUIDE_COMPONENTS}>
		<article className="markdown">{children}</article>
	</MDXProvider>
);

export default GuideMdxProvider;
