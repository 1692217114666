import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Prerequisites`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org/"
        }}>{`Node.js®`}</a>{` version `}<strong parentName="li">{`from 10 to 13`}</strong></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/"
        }}>{`npm`}</a>{` or `}<a parentName="li" {...{
          "href": "https://yarnpkg.com/"
        }}>{`yarn`}</a></li>
    </ul>
    <p><strong parentName="p">{`NPM`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-sh"
      }}>{`npm install virgil-sdk virgil-crypto`}</code></pre>
    <p><strong parentName="p">{`Yarn`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-sh"
      }}>{`yarn add virgil-sdk virgil-crypto`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      