import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<strong parentName="p">{`Virgil Core SDK PHP`}</strong>{` is provided as a package named `}<strong parentName="p">{`virgil/sdk`}</strong>{`.`}</p>
    <p>{`The package is distributed via `}<a parentName="p" {...{
        "href": "https://getcomposer.org/doc/"
      }}>{`Composer package`}</a>{` management system.`}</p>
    <p>{`The package is available for PHP version 5.6 and newer.`}</p>
    <p>{`Installing the package using Package Manager Console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "hljs language-bash"
      }}>{`composer require virgil/sdk
composer require virgil/crypto`}</code></pre>
    <p>{`Be aware `}<em parentName="p">{`virgil/crypto`}</em>{` package requires installed php virgil crypto extension `}<em parentName="p">{`ext-virgil_crypto_php`}</em>{`.`}</p>
    <p>{`Get extension from `}<a parentName="p" {...{
        "href": "https://cdn.virgilsecurity.com/virgil-crypto/php/"
      }}>{`CDN`}</a></p>
    <p>{`Also you can setup your own Crypto library inside of the SDK.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      