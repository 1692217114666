import React from 'react';
import styles from './DocsMenu.module.css';
import { MenuItem } from '*/SideMenu.yml';

import DocsMenuItem from './DocsMenuItem';

export interface DocsMenuContentPropsProps {
	items: MenuItem[];
	depth: number;
}

const DocsMenuContent: React.FC<DocsMenuContentPropsProps> = ({ items, depth }) => {
	return (
		<ul className={styles.List}>
			{items.map(item => (
				<DocsMenuItem key={item.link} item={item} depth={depth} />
			))}
		</ul>
	);
};

export default DocsMenuContent;
