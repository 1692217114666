import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import classNames from 'classnames';
import { ReactComponent as GithubSourceIcon } from 'icons/Icon-L-External.svg';

import styles from './SnippetMdxProvider.module.css';

const Pre: React.FC<React.HTMLAttributes<HTMLPreElement>> = props => {
	return (
		<pre {...props} className={classNames(styles.Pre, props.className)}>
			{props.children}
		</pre>
	);
};

interface CodeProps extends React.HTMLAttributes<HTMLDivElement> {
	tabname?: string;
	source?: string;
}

const SourceLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = props => {
	return (
		<a
			{...props}
			className={classNames(styles.action, styles.sourceLink, props.className)}
			target="_blank"
		>
			<GithubSourceIcon />
		</a>
	);
};

const Code: React.FC<CodeProps> = props => {
	let isOneLine = false;
	if (props.children) {
		isOneLine = props.children.toString().search(/\n/g) === -1;
	}
	const codeClass = classNames(styles.Code, props.className, { [styles.OneLineCode]: isOneLine });
	return (
		<code {...props} className={codeClass}>
			{props.source && (
				<div className={styles.codeActions}>
					<SourceLink href={props.source} />
				</div>
			)}
			{props.children}
		</code>
	);
};

const SnippetMdxProvider: React.FC = ({ children }) => {
	return <MDXProvider components={{ pre: Pre, code: Code }}>{children}</MDXProvider>;
};

export default SnippetMdxProvider;
