import React from 'react';
import { CodeTabs, Tab } from 'components/Tabs';
import { SnippetContext } from './GuideTemplate';
import SnippetMdxProvider from 'components/Markdown/SnippetMdxProvider';

interface SnippetTemplateProps {
	selectedLanguage?: string;
}

const SnippetTemplate: React.FC<SnippetTemplateProps> = props => {
	const [globalSelectedLang, selectGlobalLang] = React.useContext(SnippetContext);
	const registeredLanguageStorage = React.useRef<string[]>([]);
	const [registeredLanguages, setRegisteredLanguage] = React.useState<string[]>(
		registeredLanguageStorage.current,
	);
	const containerRef = React.useRef<HTMLDivElement>();
	const previousTopRef = React.useRef<number>();
	const previousLangRef = React.useRef<string>();

	React.useEffect(() => {
		if (previousTopRef.current) {
			const { top } = containerRef.current!.getBoundingClientRect();
			window.scrollTo(window.pageXOffset, window.pageYOffset + top - previousTopRef.current);
			previousTopRef.current = undefined;
		}
	});

	const selectLanguage = (lang: string) => {
		selectGlobalLang(lang);
		const { top } = containerRef.current!.getBoundingClientRect();
		previousTopRef.current = top;
		previousLangRef.current = lang;
	};

	const registerLanguage = (lang: string) => {
		registeredLanguageStorage.current = [...registeredLanguageStorage.current, lang];
		setRegisteredLanguage(registeredLanguageStorage.current);
	};

	const getLocalLanguage = () => {
		const result = registeredLanguages.find(lang => lang === globalSelectedLang);
		if (!result) return previousLangRef.current || registeredLanguages[0];
		return result;
	};

	const localSelectedLanguage = getLocalLanguage();

	enum TabName {
		cs = 'c#/.net',
		cpp = 'c++',
	}

	return (
		<SnippetMdxProvider>
			<CodeTabs
				selectedIndex={localSelectedLanguage}
				onChange={selectLanguage}
				innerRef={containerRef}
			>
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
				{React.Children.map(props.children, (child: any) => {
					const defaultLanguage = child.props.children.props.className
						.split(' ')
						.find(e => e.includes('language-'))
						.replace('language-', '');

					const language: string =
						child.props.children.props.tabname || TabName[defaultLanguage] || defaultLanguage;

					return (
						<Tab key={language} index={language} title={language} onMount={registerLanguage}>
							{child}
						</Tab>
					);
				})}
			</CodeTabs>
		</SnippetMdxProvider>
	);
};

export default SnippetTemplate;
