import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`EThree.initialize()`}</inlineCode>{` function gets the user's JWT and initializes the Virgil E3Kit for further use. The `}<inlineCode parentName="p">{`EThree.initialize()`}</inlineCode>{` function must be used on SignUp and SignIn flows.`}</p>
    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="JS BROWSER" mdxType="LanguageTab">
        <p><strong parentName="p">{`WebAssembly / asm.js`}</strong>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-javascript"
          }}><span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'@virgilsecurity/e3kit-browser'`}</span>{`; `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`//for WebAssembly`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`//or import { EThree } from '@virgilsecurity/e3kit-browser/dist/browser.asmjs.es' for asm.js`}</span>{`

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// using promise syntax`}</span>{`
EThree.initialize(tokenCallback)
  .then(`}<span parentName="code" {...{
              "className": "hljs-function"
            }}><span parentName="span" {...{
                "className": "hljs-params"
              }}>{`eThree`}</span>{` =>`}</span>{` {
    `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span>{`
  });

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// or using async/await syntax`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`const`}</span>{` eThree = `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`await`}</span>{` EThree.initialize(tokenCallback);
`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span></code></pre>
        <p><strong parentName="p">{`UMD`}</strong>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-javascript"
          }}>{`<script type=`}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`"text/javascript"`}</span>{`>
      `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`const`}</span>{` EThree = `}<span parentName="code" {...{
              "className": "hljs-built_in"
            }}>{`window`}</span>{`.E3kit.EThree;

      `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// using promise syntax`}</span>{`
      EThree.initialize(tokenCallback)
          .then(`}<span parentName="code" {...{
              "className": "hljs-function"
            }}><span parentName="span" {...{
                "className": "hljs-params"
              }}>{`eThree`}</span>{` =>`}</span>{` {
              `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span>{`
          });

      `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// or using async/await syntax`}</span>{`
      `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`const`}</span>{` eThree = `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`await`}</span>{` EThree.initialize(tokenCallback);
      `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span>{`
  </script>`}</code></pre>
  </LanguageTab>
  <LanguageTab language="NodeJS" mdxType="LanguageTab">
        <pre><code parentName="pre" {...{
            "className": "hljs language-javascript"
          }}><span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'@virgilsecurity/e3kit-node'`}</span>{`;

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// using promise syntax`}</span>{`
EThree.initialize(tokenCallback)
  .then(`}<span parentName="code" {...{
              "className": "hljs-function"
            }}><span parentName="span" {...{
                "className": "hljs-params"
              }}>{`eThree`}</span>{` =>`}</span>{` {
    `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span>{`
  });

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// or using async/await syntax`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`const`}</span>{` eThree = `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`await`}</span>{` EThree.initialize(tokenCallback);
`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span></code></pre>
  </LanguageTab>
  <LanguageTab language="React Native" mdxType="LanguageTab">
        <p>{`You need to explicitly pass `}<inlineCode parentName="p">{`AsyncStorage`}</inlineCode>{` implementation to E3Kit. Otherwise the app will crash.`}</p>
        <pre><code parentName="pre" {...{
            "className": "hljs language-js"
          }}><span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` { EThree } `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'@virgilsecurity/e3kit-native'`}</span>{`;
`}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`import`}</span>{` AsyncStorage `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "hljs-string"
            }}>{`'@react-native-community/async-storage'`}</span>{`;

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// using promise syntax`}</span>{`
EThree.initialize(tokenCallback, { AsyncStorage })
    .then(`}<span parentName="code" {...{
              "className": "hljs-function"
            }}><span parentName="span" {...{
                "className": "hljs-params"
              }}>{`eThree`}</span>{` =>`}</span>{` {
        `}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span>{`
    });

`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// or using async/await syntax`}</span>{`
`}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`const`}</span>{` eThree = `}<span parentName="code" {...{
              "className": "hljs-keyword"
            }}>{`await`}</span>{` EThree.initialize(tokenCallback, { AsyncStorage });
`}<span parentName="code" {...{
              "className": "hljs-comment"
            }}>{`// register user, encrypt, decrypt, etc.`}</span></code></pre>
        <p>{`See more usage examples in the `}<a parentName="p" {...{
            "href": "https://github.com/VirgilSecurity/virgil-e3kit-js/edit/master/packages/e3kit-native/"
          }}>{`repository`}</a>{` and in sample React Native project in `}<a parentName="p" {...{
            "href": "https://github.com/VirgilSecurity/virgil-e3kit-js/tree/master/examples/ReactNativeSample"
          }}>{`examples/ReactNativeSample`}</a>{`.`}</p>
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      