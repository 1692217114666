import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/VirgilSecurity/virgil-front-end/packages/virgil-docs/src/templates/SnippetTemplate.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "hljs language-java"
      }}>{`RatchetChannel channel = ethree.getRatchetChannel(users.get(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`));`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-kotlin"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`val`}</span>{` channel = ethree.getRatchetChannel(users[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`]!!)`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "hljs language-swift"
      }}><span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`let`}</span>{` channel = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`try`}</span>{`! eThree.getRatchetChannel(with: users[`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`"Alice"`}</span>{`])`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      