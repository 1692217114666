import React from 'react';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { canUseDOM } from '../../utils/domHelpers';
import styles from './Collapse.module.css';

export interface CollapseProps {
	title: string;
	children: string;
}

const Collapse: React.FC<CollapseProps> = ({ title, children }) => {
	// the contents inside can be indexed by search engines
	const [isExpanded, toggle] = React.useState(canUseDOM() ? false : true);
	return (
		<div className={styles.container}>
			<div role="button" tabIndex={0} className={styles.title} onClick={() => toggle(!isExpanded)}>
				<h3>{title}</h3>
				<div className={styles.titleButton}>
					<PlusIcon />
				</div>
			</div>
			{isExpanded && <div className={styles.contentExpanded}>{children}</div>}
		</div>
	);
};

export default Collapse;
