import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InstallE3KitIos from "common/e3kit/install/platforms/ios";
import InstallE3KitAndroid from "common/e3kit/install/platforms/android";
import InstallE3KitJSWeb from "common/e3kit/install/platforms/js-web";
import InstallE3KitNodejs from "common/e3kit/install/platforms/nodejs";
import InstallE3KitReactNative from "common/e3kit/install/platforms/react-native";
import InstallE3KitFlutter from "common/e3kit/install/platforms/flutter";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LanguageTabs = makeShortcode("LanguageTabs");
const LanguageTab = makeShortcode("LanguageTab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LanguageTabs mdxType="LanguageTabs">
  <LanguageTab language="iOS" mdxType="LanguageTab">
        <InstallE3KitIos mdxType="InstallE3KitIos" />
  </LanguageTab>
  <LanguageTab language="Android" mdxType="LanguageTab">
        <InstallE3KitAndroid mdxType="InstallE3KitAndroid" />
  </LanguageTab>
  <LanguageTab language="JS BROWSER" mdxType="LanguageTab">
        <InstallE3KitJSWeb mdxType="InstallE3KitJSWeb" />
  </LanguageTab>
  <LanguageTab language="NodeJS" mdxType="LanguageTab">
        <InstallE3KitNodejs mdxType="InstallE3KitNodejs" />
  </LanguageTab>
  <LanguageTab language="React Native" mdxType="LanguageTab">
        <InstallE3KitReactNative mdxType="InstallE3KitReactNative" />
  </LanguageTab>
  <LanguageTab language="Flutter" mdxType="LanguageTab">
        <InstallE3KitFlutter mdxType="InstallE3KitFlutter" />
  </LanguageTab>
    </LanguageTabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      