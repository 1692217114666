import React from 'react';

interface TabButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
	index: React.ReactText;
	title: string;
	ref?: React.RefObject<HTMLButtonElement>;
	onMount?(index: React.ReactText): void;
}

const TabButton: React.FC<TabButtonProps> = React.forwardRef<HTMLButtonElement, TabButtonProps>(
	({ title, index, onMount, className, ...rest }, ref) => {
		React.useEffect(() => onMount && onMount(index), []);
		return (
			<button ref={ref} className={className} aria-label={title} {...rest}>
				{title}
			</button>
		);
	},
);

TabButton.displayName = 'TabButton';

export default TabButton;
